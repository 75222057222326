import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "./login.css";
import { Images } from "../../assets/images/index";
import GoogleIcon from "@mui/icons-material/Google";
import Facebook from "@mui/icons-material/Facebook";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Button,
  FormControl,
  FormHelperText,
  Box,
  Stepper,
  Step,
  StepLabel,
  TextField,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import Colors from "../../assets/styles";
import {
  auth,
  provider,
  provider1,
  signInWithPopup,
} from "../../FirebaseConfig/index";
import AuthServices from "../../api/AuthServices/auth.index";
import CryptoJS from "crypto-js";
import { useAuth } from "../../context/index";
import OTPInput from "../../components/OTPINPUT";
import UserInformation from "../../views/admin/pages/RegisterUser";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { ErrorToaster, SuccessToaster } from "../../components/Toaster/index";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useNavigate } from "react-router-dom";
import { FacebookAuthProvider } from "firebase/auth";
import { useDispatch } from 'react-redux';
import { setUserData } from '../../redux/slices/UserDataSlices';
import ReCAPTCHA from "react-google-recaptcha";
// const steps = ["Enter Phone Number", "Verify OTP", "Register User"];

const Login = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [otpCode, setOtpCode] = useState("");
  const [pinCode, setPinCode] = useState(null);
  const [steps, setSteps] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [newUser, setNewUser] = useState(false);
  const [pinSetupRequired, setPinSetupRequired] = useState(false);
  const [showOtpInputs, setShowOtpInputs] = useState(false);
  const [currentInfoSection, setCurrentInfoSection] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [data, setData] = useState(null);
  const [seconds, setSeconds] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [form1, setForm1] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(false); 

  const [form2, setForm2] = useState("");
  const { userLogin } = useAuth();
  console.log(otpCode)
  const secretKey = "6UE$K3MbR5Cbtxlc";
  const navigate = useNavigate();
  const token = localStorage.getItem("token_id");

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue:setValue3,
  } = useForm();

  const handleEmail = (data) => {
    console.log(data);
    setCurrentStep(3);
    setForm1(data);
  };
  const handleName = (data) => {
    console.log(data);
    setCurrentStep(4);
    setForm2(data);
  };
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError(false);
    setValue3("captcha", value);
  };

  const registerUser = async () => {
    const obj = {
      email: form1.email,
      phone: phoneNumber,
      name: form2.fname + " " + form2.lname,
      pincode: pinCode,
      referral_code: form2.referralCode,
    };
    try {
      const data = await AuthServices.registerUrl(obj);
      dispatch(setUserData(data))

      console.log(obj);
      navigate("/home");
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit = async (data) => {
    
    if (!captchaValue) {
      setCaptchaError(true);
      return;
    }else{
      // setCaptchaError(true);
      setCurrentStep(5)
      setCaptchaValue("")

    }
  }

  const encryptData = (data) => {
    let key = CryptoJS.enc.Utf8.parse(secretKey);
    let iv = CryptoJS.enc.Utf8.parse(secretKey);
    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(JSON.stringify(data).toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  };
  const handleGoogleSignIn = async () => {
    try {
      // const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });

      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("Google Sign-In successful", user);

      localStorage.setItem("googleCredentials", JSON.stringify(user));

      console.log("Credentials saved to local storage");

      let postObj = {
        socialAuth: user.providerData[0].uid,
        login_by: "social",
        social_type: "google",
        nonce: user.providerData[0].uid,
        email: user.providerData[0].email,
        name: user.providerData[0].displayName,
      };

      console.log("Post obj:", postObj);

      let loginResult = await AuthServices.login(postObj);
      console.log("Login API call result:", loginResult);
      dispatch(setUserData(loginResult));
      localStorage.setItem("token_id", loginResult?.token);
      const token = localStorage.getItem("token_id");
      console.log("Token saved to local storage:", token);

      if (loginResult.islocked) {
        navigate("/googleAuth", { state: loginResult });
      } else if (token && token != undefined) {
        navigate("/home");
      } else {
        let firebaseParam = {
          userid: loginResult.id,
          device_token: await getFirebaseToken(),
          type: "user",
        };
        console.log("Updating Firebase token with:", firebaseParam);
        updateFirebaseToken(firebaseParam);
      }
    } catch (error) {
      console.error("Error in handleGoogleSignIn:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const handleFaceBookSignIn = async () => {
    const provider1 = new FacebookAuthProvider(); // Ensure provider is correctly initialized

    try {
      // Initiates the Facebook sign-in flow
      const result = await signInWithPopup(auth, provider1);

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // The signed-in user info
      const user = result.user;
      console.log("User:", user);

      // You can also get additional user information like name, email, etc.
      console.log("User Name:", user.displayName);
      console.log("User Email:", user.email);

      // Handle any post-sign-in actions here (e.g., redirect or save user info)
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData?.email; // Optional chaining to handle undefined email
      const credential = FacebookAuthProvider.credentialFromError(error);

      console.error("Error Code:", errorCode);
      console.error("Error Message:", errorMessage);
      console.error("Email:", email);
      console.error("Credential:", credential);

      // Handle errors such as displaying an error message to the user
    }
  };

  const handleOtpChange = (otp) => {
    setOtpCode(otp);
  };
  const handlePinChange = (pin) => {
    setPinCode(pin);
  };

  const handleLogin = async (formData) => {
    let PhNumber =
      "+" +
      (formData?.phone?.split("")[0] === "0"
        ? formData.phone.substr(1, formData.phone.length - 1)
        : formData.phone);
    let obj = { phone: PhNumber, otp: otpCode ? otpCode : pinCode };

    if (currentStep === 0) {
      let loginObj = { phone: PhNumber, type: "user" };
      let postObj = { inparm: encryptData(loginObj) };
      

      setLoader(true);

      try {
        let result = await AuthServices.login(postObj);
        console.log(result);
        localStorage.setItem("token_id", result?.token);
        dispatch(setUserData(result))
        // localStorage.setItem("phoneCredentails", JSON.stringify(result));
        setPinSetupRequired(false);
        
        if (result.islocked) {
          setNewUser(true);
          setPinSetupRequired(true);
        } else if (!result?.is_pinExist) {
          setPinSetupRequired(true);
          setData(result);
        } else {
          setNewUser(false);
          setData(result);
        }

        // Update currentStep based on is_pinExist
        if (result?.is_pinExist) {
          setShowOtpInputs(false);
          setCurrentStep(7);
          // setCurrentStep(5);
        } else {
          setShowOtpInputs(true);
          setCurrentStep(1);
        }

        setPhoneNumber(PhNumber);
        setSeconds(10);
      } catch (error) {
        ErrorToaster("Invalid number");
        console.log("error", error);
      } finally {
        setLoader(false);
      }
    } else if (newUser && currentStep !== 2) {
      validateOTP(obj).then((res) => {
        if (res?.status === 1) {
          setCurrentStep(2);
          console.log("screen", currentStep);
        } else {
          console.warn(res);
          // ErrorToaster(res?.message);
        }
      });
    } else if (pinSetupRequired && currentStep !== 2) {
      validateOTP(obj).then((res) => {
        if (res?.status === 1) {
          setCurrentStep(2);
        } else {
          console.log(res?.message);
        }
      });
    } else if (!newUser && !pinSetupRequired) {
      validateOTP(obj).then(async (res) => {
        if (res?.status === 1) {
          let firebaseParam = {
            userid: data.id,
            device_token: await getFirebaseToken(),
            type: "user",
          };
          updateFirebaseToken(firebaseParam);
          navigateToHome();
        } else {
          console.log("Invalid OTP/PIN");
        }
      });
    } else if (currentStep === 2 && pinSetupRequired) {
      setLoader(true);
      try {
        let postObj = {
          referral_code: formData?.inviteCode,
          phone: PhNumber,
          name: formData?.firstName
            ? formData.firstName + " " + formData.LastName
            : data?.name,
          email: formData.email ? formData.email : data?.email,
          pincode: formData.pinCode,
        };
        let result = await AuthServices.registerUrl(postObj);
        // localStorage.setItem("phoneCredentails", JSON.stringify(result));

        let firebaseParam = {
          userid: data.id,
          device_token: await getFirebaseToken(),
          type: "user",
        };
        updateFirebaseToken(firebaseParam);
        navigateToHome();
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoader(false);
      }
    } else if (currentStep === 2) {
      setCurrentInfoSection((prev) => prev + 1);
    }
  };

  const validateOTP = async () => {
    
  
    const obj = {
      phone: phoneNumber,
      otp: otpCode || pinCode,
    };
    setLoader(true);
    try {
      let result = await AuthServices.validateUrl(obj);
      if (pinCode && result.status == "1") {
        SuccessToaster("Logged In Successfully");
        // setCurrentStep(2);
        navigate("/home");
      } else if (pinCode && result.status == "0") {
        ErrorToaster(result?.message);
        console.log(result?.message);
      } else if (otpCode && result.status == "1") {
        setCurrentStep(2);
      } else if (otpCode && result.status == "0") {
        ErrorToaster(result?.message);
        console.log(result?.message);
      } else {
        ErrorToaster(result?.message);
        console.log(result?.message);
      }
      // setShowRegisterInputs(true);
      return result;
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }

  };
  const validateNewOtp = async () => {
    const obj = {
      phone: phoneNumber,
      otp: otpCode,
    };
    setLoader(true);
    try {
      let result = await AuthServices.validateUrl(obj);
      if (pinCode && result.status == "1") {
        SuccessToaster(result?.message);
        // setCurrentStep(2);
        navigate("/home");
      } else if (pinCode && result.status == "0") {
        ErrorToaster(result?.message);
        console.log(result?.message);
      } else if (otpCode && result.status == "1") {
        navigate("/home");
      } else if (otpCode && result.status == "0") {
        ErrorToaster(result?.message);
        console.log(result?.message);
      } else {
        ErrorToaster(result?.message);
        console.log(result?.message);
      }
      // setShowRegisterInputs(true);
      return result;
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const getFirebaseToken = async () => {
    // Implement Firebase token fetching logic here
  };
  const resendOtp = async () => {
    try {
      let obj = {
        phone: phoneNumber.replace(/\+/g, "%2B"),
        phoneOld: phoneNumber,
        type: "user",
      };
      console.warn(obj);
      let result = await AuthServices.sendOTP(
        phoneNumber.replace(/\+/g, "%2B")
      );
      setSeconds(10);
      SuccessToaster("OTP resent successfully");
    } catch (error) {
      console.log("error", error);
      ErrorHandler(error);
      ErrorToaster(error);
    } finally {
    }
  };
  const insteadOtp = async () => {
    setLoader(true)
    setCurrentStep(6)
  
    try {
      let obj = {
        phone: phoneNumber.replace(/\+/g, "%2B"),
        phoneOld: phoneNumber,
        type: "user",
      };
      console.warn(obj);
      let result = await AuthServices.sendOTP(
        phoneNumber.replace(/\+/g, "%2B")
      );
      setSeconds(10);
      SuccessToaster("OTP resent successfully");
    } catch (error) {
      console.log("error", error);
      ErrorHandler(error);
      ErrorToaster(error);
    } finally {
      setLoader(false)
    }
  };

  useEffect(() => {
    var myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (seconds === 0) {
        clearInterval(myInterval);
      } else {
        setSeconds(10);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });
  const updateFirebaseToken = (params) => {
    // Implement Firebase token update logic here
  };

  const navigateToHome = () => {
    // Implement navigation to home logic here
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="login-container">
      <header className="login-header">
        <img src={Images.logoWhite} alt="logo" className="UberLogo2" />
      </header>

      <Box sx={{ p: currentStep!= 7 ? 3 : 0}}>
        {/* Conditional rendering based on the current step */}
        {currentStep === 0 && (
          <form className="login-form" onSubmit={handleSubmit(handleLogin)}>
            <label htmlFor="phone" className="form-label">
              Enter your phone number
            </label>
            <FormControl
              sx={{
                ".form-control": {
                  height: "56px !important",
                  background: "transparent !important",
                },
              }}
              error={!!errors.phone}
              fullWidth
            >
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Phone number is required",
                  validate: (value) => {
                    if (value.trim() === "") {
                      return "Phone Number is required";
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <PhoneInput
                    country={"ky"} 
                    value={field.value}
                    onChange={field.onChange}
                    inputStyle={{ width: "100%" }}
                    countryCodeEditable={false}
                    enableSearch={true} 
                    preferredCountries={["ky"]} 
                    enableTerritories={true} 
                  />
                )}
              />
              <FormHelperText>
                {errors.phone ? errors?.phone?.message : ""}
              </FormHelperText>
            </FormControl>

            <Button
              sx={{
                mt: 2,
                background: Colors.black,
                color: Colors.white,
                ":hover": {
                  background: Colors.black,
                },
              }}
              type="submit"
              className="form-button"
            >
              {loader ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Continue"}

            </Button>
            <div className="separator">or</div>
            <button className="google-button" onClick={handleGoogleSignIn}>
              <GoogleIcon /> Continue with Google
            </button>
            {/* <button className="apple-button" onClick={handleFaceBookSignIn}>
              <Facebook  /> Continue with Facebook
            </button> */}

            <div className="terms-text">
              By proceeding, you consent to get calls, WhatsApp or SMS messages,
              including by automated means, from Lets Eat and its affiliates to
              the number provided.
            </div>
          </form>
        )}

        {currentStep === 1 && (
          <>
            <Box sx={{ fontWeight: "bold", fontSize: "20px", width: "350px" }}>
              Enter the 6 digit Code sent to you at {phoneNumber}
            </Box>
            <Box sx={{ mt: 2 }}>
              <OTPInput
                length={6}
                value={otpCode}
                onChange={handleOtpChange}
                separator={<span>-</span>}
                focusInputIndex={0}
              />

              <Box sx={{ display: "flex", gap: "10px", mt: 2 }}>
                {seconds > 0 ? (
                  <Box
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "8px 16px",
                      background: Colors.grey,
                      color: "rgb(120 120 120)",
                    }}
                  >
                    I haven't received a code {seconds}
                  </Box>
                ) : (
                  <Button
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "8px 16px",
                      background: Colors.grey,
                      color: Colors.black,
                      fontWeight: "bold",
                      ":hover": {
                        background: Colors.grey,
                      },
                    }}
                    onClick={resendOtp}
                  >
                    Resend OTP
                  </Button>
                )}
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                <Box sx={{ mt: 2 }}>
                  {currentStep > 0 && (
                    <Button
                      sx={{
                        mr: 2,
                        borderRadius: "30px",
                        padding: "8px 16px",
                        background: Colors.grey,
                        color: Colors.black,
                        fontWeight: "bold",
                        ":hover": {
                          background: Colors.grey,
                        },
                      }}
                      onClick={() => {
                        setOtpCode(""); // Clear the OTP code
                        handleBack(); // Call the handleBack function
                      }}
                    >
                      <WestIcon /> Back
                    </Button>
                  )}
                  {currentStep < steps.length - 1 && (
                    <Button
                      sx={{
                        background: Colors.black,
                        color: Colors.white,
                        ":hover": {
                          background: Colors.black,
                        },
                      }}
                      onClick={() => handleSubmit(handleLogin)()}
                    >
                      Continue
                    </Button>
                  )}
                </Box>
                <Button
                  sx={{
                    mt: 2,
                    background: Colors.black,
                    color: Colors.white,
                    borderRadius: "30px",
                    padding: "8px 16px",
                    ":hover": {
                      background: Colors.black,
                    },
                  }}
                  onClick={handleSubmit(handleLogin)}
                >
                  Next <EastIcon />
                </Button>
              </Box>
            </Box>

          </>
        )}

        {currentStep === 2 && (
          <>
            <form onSubmit={handleSubmit1(handleEmail)}>
              <Box
                sx={{ fontWeight: "bold", fontSize: "20px", width: "350px" }}
              >
                What's your Email Address
              </Box>

              <Box sx={{ mt: 2 }}>
                <TextField
                  placeholder="Email Address"
                  type="email"
                  variant="outlined"
                  fullWidth
                  {...register1("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors1.email}
                  helperText={errors1.email ? errors1.email.message : ""}
                />
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
              >
                <Box sx={{ mt: 2 }}>
                  {currentStep > 0 && (
                    <Button
                      sx={{
                        mr: 2,
                        borderRadius: "30px",
                        padding: "8px 16px",
                        background: Colors.grey,
                        color: Colors.black,
                        fontWeight: "bold",
                        ":hover": {
                          background: Colors.grey,
                        },
                      }}
                      onClick={handleBack}
                    >
                      <WestIcon />
                      Back
                    </Button>
                  )}
                  {currentStep < steps.length - 1 && (
                    <Button
                      sx={{
                        background: Colors.black,
                        color: Colors.white,
                        ":hover": {
                          background: Colors.black,
                        },
                      }}
                    >
                      Continue
                    </Button>
                  )}
                </Box>
                <Button
                  type="submit"
                  sx={{
                    mt: 2,
                    background: Colors.black,
                    color: Colors.white,
                    borderRadius: "30px",
                    padding: "8px 16px",
                    ":hover": {
                      background: Colors.black,
                    },
                  }}
                >
                  Next <EastIcon />
                </Button>
              </Box>
            </form>
            {/* <UserInformation
              watch={watch}
              setPinCode={setPinCode}
              control={control}
              currentInfoSection={currentInfoSection}
              loginData={data}
            /> */}
          </>
        )}
        {currentStep === 3 && (
          <>
            <form onSubmit={handleSubmit2(handleName)}>
              <Box
                sx={{ fontWeight: "bold", fontSize: "20px", width: "350px" }}
              >
                What’s your Name
              </Box>
              <Box
                sx={{
                  mt: 3,
                  fontWeight: "500",
                  fontSize: "13px",
                  width: "350px",
                }}
              >
                Let us Know how to properly address you
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  placeholder="First Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  {...register2("fname", {
                    required: "First Name is required",
                  })}
                  error={!!errors2.fname}
                  helperText={errors2.fname ? errors2.fname.message : ""}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <TextField
                  placeholder="Last Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  {...register2("lname", { required: "last Name is required" })}
                  error={!!errors2.lname}
                  helperText={errors2.lname ? errors2.lname.message : ""}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <TextField
                  placeholder="Referral Code (optional)"
                  type="text"
                  variant="outlined"
                  fullWidth
                  {...register2("referralCode")}
                />
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
              >
                <Box sx={{ mt: 2 }}>
                  {currentStep > 0 && (
                    <Button
                      sx={{
                        mr: 2,
                        borderRadius: "30px",
                        padding: "8px 16px",
                        background: Colors.grey,
                        color: Colors.black,
                        fontWeight: "bold",
                        ":hover": {
                          background: Colors.grey,
                        },
                      }}
                      onClick={handleBack}
                    >
                      <WestIcon />
                      Back
                    </Button>
                  )}
                  {currentStep < steps.length - 1 && (
                    <Button
                      sx={{
                        background: Colors.black,
                        color: Colors.white,
                        ":hover": {
                          background: Colors.black,
                        },
                      }}
                    >
                      Continue
                    </Button>
                  )}
                </Box>
                <Button
                  type="submit"
                  sx={{
                    mt: 2,
                    background: Colors.black,
                    color: Colors.white,
                    borderRadius: "30px",
                    padding: "8px 16px",
                    ":hover": {
                      background: Colors.black,
                    },
                  }}
                >
                  Next <EastIcon />
                </Button>
              </Box>
            </form>
            {/* <UserInformation
              watch={watch}
              setPinCode={setPinCode}
              control={control}
              currentInfoSection={currentInfoSection}
              loginData={data}
            /> */}
          </>
        )}
        {currentStep === 4 && (
          <>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                width: "350px",
                textAlign: "center",
              }}
            >
              Enter your 4 digit Unique PIN
            </Box>
            <Box
              sx={{
                mt: 3,
                fontWeight: "500",
                fontSize: "13px",
                width: "350px",
                textAlign: "center",
              }}
            >
              The Unique PIN will help you to login without OTP
            </Box>
            <Box sx={{ mt: 2, justifyContent: "center" }}>
              <OTPInput
                length={4}
                value={pinCode}
                onChange={handlePinChange}
                focusInputIndex={0}
                separator={<span>-</span>}
              />
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
            >
              <Box sx={{ mt: 2 }}>
                {currentStep > 0 && (
                  <Button
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "8px 16px",
                      background: Colors.grey,
                      color: Colors.black,
                      fontWeight: "bold",
                      ":hover": {
                        background: Colors.grey,
                      },
                    }}
                    onClick={handleBack}
                  >
                    <WestIcon />
                    Back
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button
                    sx={{
                      background: Colors.black,
                      color: Colors.white,
                      ":hover": {
                        background: Colors.black,
                      },
                    }}
                  >
                    Continue
                  </Button>
                )}
              </Box>
              <Button
                onClick={registerUser}
                sx={{
                  mt: 2,
                  background: Colors.black,
                  color: Colors.white,
                  borderRadius: "30px",
                  padding: "8px 16px",
                  ":hover": {
                    background: Colors.black,
                  },
                }}
              >
                {loader ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Next"}
                <EastIcon />
              </Button>
            </Box>
          </>
        )}
        {currentStep === 5 && (
          <>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                width: "80%",
                display:"flex",
                justifyContent:"center",
                margin:"0 auto",
                textAlign: "center",
              }}
            >
              Enter your 4 digit PIN that you have set to this Number
            </Box>

            <Box sx={{ mt: 2, justifyContent: "center" }}>
              <OTPInput
                length={4}
                value={pinCode}
                onChange={handlePinChange}
                focusInputIndex={0}
                separator={<span>-</span>}
              />
              
            </Box>
            <Box sx={{width:"80%",margin:"0 auto"}}>

          
            <Button
              sx={{
                ml: 3,
                mt: 3,
                borderRadius: "30px",
                padding: "8px 16px",
                background: Colors.grey,
                color: Colors.black,
                fontWeight: "bold",
                ":hover": {
                  background: Colors.grey,
                },
              }}

              onClick={insteadOtp}
            >
              {loader1 ? <CircularProgress sx={{ color: "white" }} size={20} /> : " Use OTP instead"}

            </Button>  </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}
            >
              <Box sx={{ mt: 2 }}>
                {currentStep > 0 && (
                  <Button
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "8px 16px",
                      background: Colors.grey,
                      color: Colors.black,
                      fontWeight: "bold",
                      ":hover": {
                        background: Colors.grey,
                      },
                    }}
                    onClick={() => setCurrentStep(0)}
                  >
                    <WestIcon />
                    Back
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button
                    sx={{
                      background: Colors.black,
                      color: Colors.white,
                      ":hover": {
                        background: Colors.black,
                      },
                    }}
                  >
                    Continue
                  </Button>
                )}
              </Box>
              <Button
                onClick={validateOTP}
                sx={{
                  mt: 2,
                  background: Colors.black,
                  color: Colors.white,
                  borderRadius: "30px",
                  padding: "8px 16px",
                  ":hover": {
                    background: Colors.black,
                  },
                }}
              >
                {loader ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Next"}
                <EastIcon />
              </Button>
            </Box>
          </>
        )}
        {currentStep === 6 && (
          <>
            <Box sx={{ fontWeight: "bold", fontSize: "20px", width: "350px" }}>
              Enter the 6 digit Code sent to you at {phoneNumber}
            </Box>
            <Box sx={{ mt: 2 }}>
              <OTPInput
                length={6}
                value={otpCode}
                onChange={handleOtpChange}
                focusInputIndex={0}
                separator={<span>-</span>}
              />

              <Box sx={{ display: "flex", gap: "10px", mt: 2 }}>
                {seconds > 0 ? (
                  <Box
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "8px 16px",
                      background: Colors.grey,
                      color: "rgb(120 120 120)",
                    }}
                  >
                    I haven't recieved a code {seconds}
                  </Box>
                ) : (
                  <Button
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "8px 16px",
                      background: Colors.grey,
                      color: Colors.black,
                      fontWeight: "bold",
                      ":hover": {
                        background: Colors.grey,
                      },
                    }}
                    onClick={resendOtp}
                  >
                    Resend OTP
                  </Button>
                )}
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
              >
                <Box sx={{ mt: 2 }}>
                  {currentStep > 0 && (
                    <Button
                      sx={{
                        mr: 2,
                        borderRadius: "30px",
                        padding: "8px 16px",
                        background: Colors.grey,
                        color: Colors.black,
                        fontWeight: "bold",
                        ":hover": {
                          background: Colors.grey,
                        },
                      }}
                      onClick={handleBack}
                    >
                      <WestIcon /> Back
                    </Button>
                  )}
                  {currentStep < steps.length - 1 && (
                    <Button
                      sx={{
                        background: Colors.black,
                        color: Colors.white,
                        ":hover": {
                          background: Colors.black,
                        },
                      }}
                      onClick={() => handleSubmit(handleLogin)()}
                    >
                      Continue
                    </Button>
                  )}
                </Box>
                <Button
                  sx={{
                    mt: 2,
                    background: Colors.black,
                    color: Colors.white,
                    borderRadius: "30px",
                    padding: "8px 16px",
                    ":hover": {
                      background: Colors.black,
                    },
                  }}
                  onClick={validateNewOtp}
                // onClick={handleSubmit(handleLogin)}
                >
                  {loader ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Next"}
                  <EastIcon />
                </Button>
              </Box>
            </Box>
          </>
        )}
         {currentStep === 7 && (
          <>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
             
                textAlign: "center",
                margin:"0 auto"
              }}
            >
              Verify you are not a Robot
            </Box>
            <Box component={"form"} onSubmit={handleSubmit3(onSubmit)} sx={{mt:2}}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      borderRadius: "4px",
                      padding: "16px",
                      textAlign: "center",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {/* Google reCAPTCHA Component */}
                    <ReCAPTCHA
                      sitekey="6Ler8UkcAAAAAP4IKjtdHGSaUrARnJ8nQJ4TOLVs" // Replace with your actual site key
                      onChange={handleCaptchaChange}
                    />
                    {captchaError && (
                      <Typography  variant="body2" color="error">
                        Captcha is required.
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Box
              sx={{ display: "flex", justifyContent: "space-around",width:"100%",  mt: 4 }}
            >
              <Box sx={{ mt: 2 }}>
                {currentStep > 0 && (
                  <Button
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "8px 16px",
                      background: Colors.grey,
                      color: Colors.black,
                      fontWeight: "bold",
                      ":hover": {
                        background: Colors.grey,
                      },
                    }}
                    onClick={() => setCurrentStep(0)}
                  >
                    <WestIcon />
                    Back
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button
                    sx={{
                      background: Colors.black,
                      color: Colors.white,
                      ":hover": {
                        background: Colors.black,
                      },
                    }}
                  >
                   {loader ? (
                  <CircularProgress sx={{ color: "white" }} size={20} />
                ) : (
                  "Continue"
                )}
                  </Button>
                )}
              </Box>
              <Button
                type="submit"
                sx={{
                  mt: 2,
                  background: Colors.black,
                  color: Colors.white,
                  borderRadius: "30px",
                  padding: "8px 16px",
                  ":hover": {
                    background: Colors.black,
                  },
                }}
              >
                {loader ? (
                  <CircularProgress sx={{ color: "white" }} size={20} />
                ) : (
                  "Next"
                )}
                <EastIcon />
              </Button>
            </Box>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default Login;
