import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Button,
  Grid,
  TextField,
  Paper,
  IconButton,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { giftCardApiCall } from '../../../../api/giftCard';
import { ArrowBackIosNew } from '@mui/icons-material';
import Colors from '../../../../assets/styles';
import { ErrorToaster } from '../../../../components/Toaster';

const CustomizeGift = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [giftAmount, setGiftAmount] = useState('');
  const [giftAmountError, setGiftAmountError] = useState(false);

  const { control, handleSubmit, setValue, formState: { errors } } = useForm();
  const userData = useSelector(state => state.userData.userData);

  useEffect(() => {
    // Scroll to top on pathname change
    window.scrollTo(0, 0);
  }, [location]);


  useEffect(() => {
    setValue('fromName', userData?.name);
  }, [userData, setValue]);

  const createGiftCard = async (value) => {
    setLoading(true);
    try {
      if (giftAmount !== '') {
        setGiftAmountError(false);
        let obj = {
          gift_coverId: data?.coverId,
          amount: giftAmount,
          from: value.fromName,
          to: value?.toName,
          description: value?.senderNote,
          created_by: userData?.id,
        };
        const result = await giftCardApiCall.createGiftCard(obj);
        if (result?.status === "0") {
          ErrorToaster(result?.message);
        } else if (result?.status === "1") {
          navigate('/gift-checkout', {
            state: { 
                toName: value?.toName,
                fromName: value.fromName,
                senderNote: value.senderNote,
                giftAmount: giftAmount,
                giftData: data,
                giftCardCode: result?.giftCardCode,
                giftCardId: result?.giftcardID,
            },
          });
        }
      } else {
        setGiftAmountError(true);
      }
    } catch (error) {
      console.error('Error creating gift card:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ maxWidth: "900px", mt: {md:10,sm:10,xs:12} }}>
      <Box mt={4}>
        <Box sx={{ fontWeight: "bold", display: "flex", gap: "10px", alignItems: "center" }}>
          <Box sx={{ background: Colors.grey, borderRadius: "50%", width: "40px" }}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosNew />
            </IconButton>
          </Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>Customize Your Gifts</Typography>
        </Box>

        <Paper elevation={3} sx={{ padding: 2, marginTop: 3 }}>
          <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
            <img
              src={baseUrl + 'docs/files/giftCards/' + data?.pic}
              alt="Gift Cover"
              style={{ width: '100%', height: 'auto', maxHeight: 200 }}
            />
          </Box>

          <Box mb={3}>
            <Typography variant="h6">Gift amount</Typography>
            {giftAmount && (
              <Typography variant="h4" sx={{ margin: '10px 0' }}>
                ${parseFloat(giftAmount).toFixed(2)}
              </Typography>
            )}
            <Grid container spacing={2}>
              {data?.AmountList?.map((item, index) => (
                <Grid item key={index}>
                  <Button
                    sx={{
                      backgroundColor: item === giftAmount ? Colors.black : Colors.grey,
                      color: item === giftAmount ? Colors.white : Colors.black,
                      '&:hover': {
                        backgroundColor: item === giftAmount ? Colors.black : Colors.grey,
                      },
                    }}
                    onClick={() => {
                      setGiftAmountError(false);
                      setGiftAmount(item);
                    }}
                  >
                    ${item}
                  </Button>
                </Grid>
              ))}
            </Grid>
            {giftAmountError && (
              <Typography color="error" sx={{ marginTop: 2 }}>
                Select gift amount to continue
              </Typography>
            )}
          </Box>

          <Box mb={3}>
            <Typography variant="h6">Who's this gift for?</Typography>
            <Controller
              name="toName"
              control={control}
              rules={{ required: 'Receiver name is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Enter their name or nickname"
                  variant="outlined"
                  margin="normal"
                  error={!!errors.toName}
                  helperText={errors.toName ? errors.toName.message : ''}
                />
              )}
            />
          </Box>

          <Box mb={3}>
            <Typography variant="h6">Who's this gift card from?</Typography>
            <Controller
              name="fromName"
              control={control}
              rules={{ required: 'Sender name is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Enter sender name or nickname"
                  variant="outlined"
                  margin="normal"
                  error={!!errors.fromName}
                  helperText={errors.fromName ? errors.fromName.message : ''}
                />
              )}
            />
          </Box>

          <Box mb={3}>
            <Typography variant="h6">Add a message (optional)</Typography>
            <Controller
              name="senderNote"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Enter your note here ..."
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                />
              )}
            />
          </Box>

          <Box display="flex" justifyContent="center">
            <Button
              loading={loading}
              disabled={!userData?.id}
              onClick={handleSubmit(createGiftCard)}
              sx={{
                background: Colors.black,
                color: Colors.white,
                width: '100%',
                ":hover": {
                  background: Colors.black,
                }
              }}
            >
              {userData?.id ? 'Checkout' : 'Login to create Gift Card'}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default CustomizeGift;
