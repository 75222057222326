import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import "./OrderStatus.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Images } from "../../../../assets/images";
import { orderApiCall } from "../../../../api/OrderServices";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Colors from "../../../../assets/styles";
import { useNavigate } from "react-router-dom";
import { ArrowBackIosNew } from "@mui/icons-material";

const ToggleButton = ({ selected, setSelected }) => {
  return (
    <Box className="toggle-button">
      <Box
        className={`toggle-slider ${
        selected === "Reorder" ? "slide-right" : ""
        }`}
      ></Box>
      <Box
        className={`toggle-option ${selected === "All Orders" ? "active" : ""}`}
        onClick={() => setSelected("All Orders")}
      >
        All Orders
      </Box>
      <Box
        className={`toggle-option ${selected === "Reorder" ? "active" : ""}`}
        onClick={() => setSelected("Reorder")}
      >
        Reorder
      </Box>
    </Box>
  );
};

const OrderStatus = () => {
  const [selectedToggle, setSelectedToggle] = useState("All Orders");
  const [pageNo, setPageNo] = useState(1);
  const [orders, setOrders] = useState([]);
  const [footerLoader, setFooterLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const userData = useSelector((state) => state.userData.userData);
  const [stopPagination, setStopPagination] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const getMyOrders = async (isLoadMore = false) => {
    if (isLoadMore) {
      setFooterLoader(true);
    } else {
      setLoader(true);
      setPageNo(1);
      setOrders([]); // Clear previous orders when not loading more
    }

    const params = {
      user_id: userData?.id,
      IsPagination: true,
      page: isLoadMore ? pageNo : 1,
      limit: 10,
    };

    try {
      const result = await orderApiCall.getOrders(params);
      if (isLoadMore) {
        setOrders((prevOrders) => [...prevOrders, ...result]);
      } else {
        setOrders(result);
      }

      if (result?.length < 10) {
        setStopPagination(true);
      } else {
        setStopPagination(false);
      }

      if (isLoadMore) {
        setPageNo((prevPageNo) => prevPageNo + 1);
      } else {
        setPageNo(2);
      }
    } catch (error) {
      console.log("Error fetching orders:", error);
    } finally {
      setLoader(false);
      setFooterLoader(false);
    }
  };

  const placeReOrder = async (item) => {
    setLoader(true);
    const params = {
      orderid: item?.orderid,
    };

    try {
      const result = await orderApiCall.placeReorder(params);

      if (item?.service_type === 'parcel') {
        navigate('/ParcelCheckout', {
          selectedShop: 0,
          cartData: result,
          basedOn: result?.based_on,
          fromReorder: true,
          orderid: item?.orderid,
        });
      } else {
        navigate('/checkout', { state: { itemData: result, itemIndex: 0, fromReorder: true } });
      }
    } catch (error) {
      console.log('Error placing reorder:', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getMyOrders();
  }, []);

  return (
    <>
      <Box className="header-container" sx={{ mt: {md:12,xs:14} }}>
        <Box className="left-section">
          <Box className="back-button" sx={{display:"flex" ,gap:"10px",alignItems:"center"}}>
          <Box sx={{background:Colors.grey,borderRadius:"50%",width:"40px"}}>
              <IconButton onClick={()=>navigate(-1)}> 
                <ArrowBackIosNew />
              </IconButton>
            </Box>
            <p className="my-orders-heading">My Orders</p>
          </Box>
        </Box>
        <ToggleButton
          selected={selectedToggle}
          setSelected={setSelectedToggle}
        />
      </Box>
      {orders.length === 0 ? (
        <Box className="container">
          <AssignmentIcon className="icon" />
          <Typography variant="h5">No orders yet</Typography>
          <Typography variant="body1" color="textSecondary">
            When you place your first order, it will appear here
          </Typography>
          <Button variant="contained" className="styled-button" onClick={()=>navigate("/home")}>
            Find Food
          </Button>
        </Box>
      ) : (
        <Box className="order-container" display="flex"  justifyContent="center" gap={3} sx={{padding:"15px"}}> 
        {orders.map((order) => (
          <Card
            key={order.id}
            className="order-card"
            sx={{
              width: {md:300,sm:"100%",xs:"100%"},
              boxShadow: 3,
              borderRadius: 3,
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.03)" },
            }}
          >
            <CardMedia
              component="img"
              height="150"
              sx={{width:"162px" ,height:"100%"}}
              image={
                order.based_on === "shop"
                  ? `${baseUrl}/docs/files/shops/banner/${order.default_banner}`
                  : `${baseUrl}/docs/files/stores/banner/${order.default_banner}`
              }
              alt={order.shopname}
            />
            <CardContent sx={{ p: {lg:"15px 20px !important",md:"15px 20px !important",sm:"15px 20px !important  ",xs:"5px 16px !important" }}}>
              <Typography variant="h6" sx={{fontSize:{lg:"16px",md:"16px",sm:"16px",xs:"14px"}}} fontWeight="bold">
                {order.shopname}
              </Typography>
              <Typography variant="body2" fontSize="0.775rem" color="textSecondary">
                Order #{order.orderid} • {order.items.length}{" "}
                {order.items.length === 1 ? "item" : "items"} • ${order.total_pay}
              </Typography>
              <Typography variant="body2" fontSize="0.775rem" color="textSecondary">
                {moment(order.created_at).format("MMM DD, YYYY")}
              </Typography>
              <Box display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="body2" sx={{ display: "flex", alignItems: "center",fontSize:"0.875rem",fontWeight:"bold" }}>
                  <span className="status-dot" style={{ backgroundColor: order.status === "Delivered" ? "green" : "orange" }} />
                  &nbsp; {order.status}
                </Typography>
              </Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: 50, textTransform: "none",mt:1 }}
                  onClick={() =>
                    selectedToggle === "Reorder"
                      ? placeReOrder(order)
                      : navigate("/order-details", { state: order.orderid })
                  }
                >
                  {selectedToggle === "Reorder" ? "Reorder" : "View"}
                </Button>
            </CardContent>
          </Card>
        ))}
      </Box>
      )}
      {!stopPagination && orders.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{backgroundColor:Colors.black,":hover":{
              backgroundColor:Colors.black
            }}}
            onClick={() => getMyOrders(true)}
            disabled={footerLoader}
          >
            {footerLoader ? 'Loading...' : 'Load More'}
          </Button>
        </Box>
      )}
    </>
  );
};

export default OrderStatus;
