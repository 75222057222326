import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  setCurrenLocation,
  setUserLocation,
  setUserPickupLocation,
  setUserDropoffLocation,
} from "../../redux/slices/UserDataSlices";
import { Images } from "../../assets/images"; // Adjust based on your path
import LocationAddressServices from "../../api/LocationAddressServices";
import NearMeIcon from "@mui/icons-material/NearMe";
import Colors from "../../assets/styles";
import EditIcon from "@mui/icons-material/Edit";
import AutoComplete from "../AutoComplete";
import { Favorite, Home, Work } from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { FormControl, TextareaAutosize } from "@mui/base";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SuccessToaster } from "../Toaster";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useForm } from "react-hook-form";
import LocationOnIcon from '@mui/icons-material/LocationOn';

const LocationDetail = ({ stateData ,setDialog}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const placeRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(stateData);
  const { fromCheckout, addressType, serviceType } = location.state || {};
  const userData = useSelector((state) => state.userData.userData);
  const addressData = useSelector((state) => state.userData.currentLocation);
  console.log(addressData)
  const currentLocation = useSelector(
    (state) => state.userDataReducer?.currentLocation
  );
  const userSelectedAddress = useSelector(
    (state) => state.userDataReducer?.selectedAddress
  );
  const [updatedAddress, setUpdatedAddress] = useState("");
  const [activeScreen, setActiveScreen] = useState(0);
  const [activeScreen1, setActiveScreen1] = useState(0);
  const [userAddress, setUserAddress] = useState([]);
  const [valueName, setValueName] = useState(null);
  const [loader, setLoader] = useState(false);
  // const [selectedLocation, setSelectedLocation] = useState(null);
  const [openAccordion, setOpenAccordion] = useState("");
  const [skipStep, setSkipStep] = useState(false);
  const [leaveOption, setLeaveOption] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({
    lat: addressData?.latitude || "",
    lng: addressData?.longitude || "",
  });
  const [formInputs, setFormInputs] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
    input7: "",
    input8: "",
    input9: "",
    handMe: "",
    leave: "",
  });
  const [selectedLabel, setSelectedLabel] = useState("");

  useEffect(() => {
    if (addressData) {
      setSelectedLocation({
        lat: addressData.latitude,
        lng: addressData.longitude,
      });
    }
  }, [addressData])
  const [state, setState] = useState({
    openAccordion: "handItToMe",
    deliveryOption: "",
    leaveOption: "",
  });

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setState((prevState) => ({
      ...prevState,
      openAccordion: isExpanded ? panel : "",
    }));
  };
  useEffect(() => {
    // Set the initial deliveryOption based on API data
    if (formInputs?.handMe?.meet_at_door) {
      setState((prevState) => ({ ...prevState, deliveryOption: "meet_door" }));
    } else if (formInputs?.handMe?.meet_at_outside) {
      setState((prevState) => ({ ...prevState, deliveryOption: "outside" }));
    } else if (formInputs?.handMe?.meet_at_lobby) {
      setState((prevState) => ({ ...prevState, deliveryOption: "lobby" }));
    }
    if (formInputs?.leave?.leave_at_door) {
      setState((prevState) => ({ ...prevState, leaveOption: "leave_door" }));
    } else if (formInputs?.leave?.leave_at_reception) {
      setState((prevState) => ({ ...prevState, leaveOption: "reception" }));
    } else if (formInputs?.leave?.leave_at_building_door) {
      setState((prevState) => ({ ...prevState, leaveOption: "buildingDoor" }));
    }
  }, [formInputs?.handMe, formInputs?.leave]);

  const handleOptionChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      deliveryOption: event.target.value,
      leaveOption: "", // Clear leaveOption when a deliveryOption is selected
    }));
  };
  const handleLeaveOptionChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      leaveOption: event.target.value,
      deliveryOption: "", // Clear deliveryOption when a leaveOption is selected
    }));
  };

  const handleUpdate = () => {
    // Handle form submission
    console.log({
      deliveryOption: state.deliveryOption,
      leaveOption: state.leaveOption,
    });
    setActiveScreen(2);
  };

  const dispatch = useDispatch();
  console.log(selectedLocation);
  // Function to update location with autocomplete
  const updateLocationWithAutoComplete = (lat, lng, address) => {
    console.log(lat, lng, address);
    // navigate("/addressDetail", {
    //   state: {
    //     address,
    //     lat,
    //     lng,
    //     serviceType,
    //   },
    // });
  };

  // API Call to get customer address
  const getCustomerAddress = async () => {
    const obj = {
      user_id: userData?.id,
    };
    try {
      const result =
        location?.state?.pd_status === "parcel"
          ? await LocationAddressServices.getParcelAddress(obj)
          : await LocationAddressServices.getCustomerAddress(obj);
      setUserAddress(result);
    } catch (error) {
      console.error("Error fetching customer addresses:", error);
    }
  };

  // Function to delete customer address
  const deleteCustomerAddress = async (id) => {
    try {
      const response = await axios.delete("/api/deleteCustomerAddress", {
        data: { id },
      });
      if (response.data.status === "1") {
        getCustomerAddress();
      }
      if (id === userSelectedAddress?.id && userAddress?.length > 1) {
        const findLeftItem = userAddress?.find((item) => item?.id !== id);
        dispatch(setUserLocation(findLeftItem));
      } else {
        dispatch(setUserLocation({}));
      }
    } catch (error) {
      console.error("Error deleting customer address:", error);
    }
  };

  // Function to handle location permission
  const permissionCheck = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position)
          const { latitude, longitude } = position.coords;
          dispatch(setCurrenLocation({ latitude, longitude }));
          updateLocationWithAutoComplete(
            latitude,
            longitude,
            location.state?.pd_status
          );
        },
        (error) => {
          console.error("Error getting location:", error);
        },
        { enableHighAccuracy: true }
      ); 
      setFormInputs({})
      setValueName("")
      setActiveScreen(1)
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getCustomerAddress();
  }, []);

  const handleEditClick = (item) => {
    setSkipStep(true)
    console.log(item);
    setActiveScreen(2);
    if(item){
      setSelectedLabel(item.type);
      setFormInputs({
        id: item.id || "",
      input1: item.building || "",
      input2: item.map_address || "",
      input3: item.type || "",
      input4: item.city || "",
      input5: item.pincode || "",
      input6: item.instructions || "",
      input7: item.street || "",
      input8: item.addressLabel || "",
      input9: item.landmark || "",
      handMe: item.HandItToMe || "",
      leave: item?.LeaveAtLocation || "",
      lat: item?.latitude || "",
      lng: item?.longitude || "",
    });
  }else{
    setFormInputs({})
  }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const addAddressDetails = async (formData) => {
    console.log(skipStep);
    let radioObj = {
      HandItToMe: {
        meet_at_door: state.deliveryOption === "meet_door",
        meet_at_outside: state.deliveryOption === "outside",
        meet_at_lobby: state.deliveryOption === "lobby",
      },
      LeaveAtLocation: {
        leave_at_door: state.leaveOption === "leave_door",
        leave_at_reception: state.leaveOption === "reception",
        leave_at_building_door: state.leaveOption === "buildingDoor",
      },
    };

    console.log(radioObj);
    let obj = {
      // ...(data?.item && {id: data?.item?.id}),
      user_id: userData?.id,
      building: formData.input1,
      landmark: formData.input9,
      map_address: formInputs.input2 || valueName || address,
      latitude: formInputs.lat || selectedLocation.lat,
      longitude: formInputs.lng || selectedLocation.lng,
      city: formData.input4,
      type: selectedLabel,
      addressLabel: formData.input8,
      instructions: formData?.input6,
      HandItToMe: radioObj?.HandItToMe,
      LeaveAtLocation: radioObj?.LeaveAtLocation,
      // newly added
      street: formData.input9,
      pincode: formData.input5,
    };
    if (skipStep) {
      obj.id = formInputs?.id;
    }
    console.log(obj);
    try {
      if (skipStep) {
        let updated =
          stateData?.pd_status == "parcel"
            ? await LocationAddressServices.updatedParcelAddressDetails(obj)
            : await LocationAddressServices.updatedAddressDetails(obj);
        SuccessToaster("Address edited successfully");
        setActiveScreen(0)
      } else {
        let result =
          stateData?.pd_status == "parcel"
            ? await LocationAddressServices.addParcelAddressDetails(obj)
            : await LocationAddressServices.addAddressDetails(obj);
        SuccessToaster("Address added successfully");
        setActiveScreen(0)
      }
    } catch (error) {
      ErrorToaster("Something went wrong.");
      console.log("error", error);
    }finally{
      getCustomerAddress();
    }
  };

  const [address, setAddress] = useState("");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC1Gh661ToYHtDs98IVxaZHi5_XqVMwy6Y",
    libraries: ["places"],
  });

  useEffect(() => {
    if (addressData) {
      setSelectedLocation({
        lat: addressData.latitude,
        lng: addressData.longitude,
      });
      reverseGeocode(addressData.latitude, addressData.longitude);
    }
  }, [addressData]);

  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const location = { lat, lng };

    geocoder.geocode({ location }, (results, status) => {
      if (status === "OK" && results[0]) {
        setAddress(results[0].formatted_address);
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };

  const handleMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setSelectedLocation({ lat: newLat, lng: newLng });
    reverseGeocode(newLat, newLng);
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      {activeScreen === 0 && (
        <>
          <AutoComplete
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            activeScreen={activeScreen}
            setActiveScreen={setActiveScreen}
            reverted={false}
            setValueName={setValueName}
            value={updatedAddress}
            clearInput={() => setUpdatedAddress("")}
            placeRef={placeRef}
            keepResultsAfterBlur={false}
            onPress={(data, details) => {
              updateLocationWithAutoComplete(
                details.geometry.location.lat,
                details.geometry.location.lng,
                data.description
              );
            }}
          />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Nearby
            </Typography>
            <Button
              sx={{ color: Colors.black }}
              onClick={permissionCheck}
              startIcon={<NearMeIcon />}
            >
              Use current location
            </Button>
          </Box>
          <Box>
            <Divider />
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
              Recent Locations
            </Typography>
            {loader ? (
              <CircularProgress />
            ) : (
              <List sx={{
                overflow: "auto",
                height: "300px",
              }}>
  {userAddress.map((item) => (
    <ListItem
      key={item.id}
      button
      onClick={() => {
        if (addressType === "pickup") {
          dispatch(setUserPickupLocation(item));
          setDialog(false);
        } else if (addressType === "dropoff") {
          dispatch(setUserDropoffLocation(item));
          setDialog(false);
        } else {
          dispatch(setUserLocation(item));
          setDialog(false);
        }
      }}
    >
      <LocationOnIcon style={{ marginRight: 8 }} />
      <ListItemText
        primary={item.building}
        secondary={`${item.map_address} - Type: ${item.type}`}
      />
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleEditClick(item);
        }}
      >
        <EditIcon />
      </IconButton>
      {/* <IconButton onClick={() => deleteCustomerAddress(item.id)}>
        <img src={Images.delete} alt="Delete" />
      </IconButton> */}
    </ListItem>
  ))}
</List>
            )}
          </Box>
        </>
      )}
        { activeScreen === 1 && (addressData || selectedLocation) && (
      <>
        <Box sx={{ cursor: "pointer", mb: 1 }} onClick={() => setActiveScreen(0)}>
          <ArrowBackIosIcon />
        </Box>
        <GoogleMap
          mapContainerStyle={{ height: "400px", width: "100%" }}
          center={selectedLocation}
          zoom={15}
        >
          <Marker
            position={selectedLocation}
            draggable
            onDragEnd={handleMarkerDragEnd}
          />
        </GoogleMap>

        {/* <Box sx={{ mt: 2, textAlign: "center" }}>
          <strong>Address:</strong> {address || "Fetching address..."}
        </Box> */}

        <Box
          onClick={() => {
            setActiveScreen(2);
            setSkipStep(false);
            setFormInputs({
              input1: "",
              input2: "",
              input3: "",
              input4: "",
              input5: "",
              input6: "",
              input7: "",
              input8: "",
              input9: "",
              handMe: "",
              leave: "",
            })
          }}
          sx={{
            cursor: "pointer",
            background: Colors.black,
            color: Colors.white,
            width: "150px",
            p: 1.5,
            display: "flex",
            justifyContent: "center",
            margin: "20px auto",
            borderRadius: "10px",
          }}
        >
          Confirm location
        </Box>
      </>
    )}

      {activeScreen === 2 && (
        <>
        <Box sx={{cursor:"pointer"}} onClick={()=>{
          if(skipStep){
            setActiveScreen(0)
            setFormInputs({})
            console.log(formInputs)
          }else{
            setActiveScreen(1)

          }
        }}>
          <ArrowBackIosIcon/>
        </Box>
          <Box component="form" onSubmit={handleSubmit(addAddressDetails)}>
          <GoogleMap
          mapContainerStyle={{ height: "200px", width: "100%" }}
          center={selectedLocation}
          zoom={15}
        >
          <Marker
            position={selectedLocation}
            // draggable
            // onDragEnd={handleMarkerDragEnd}
          />
        </GoogleMap>
            <Box sx={{ fontWeight: "bold", py: 1.5, fontSize: "18px" }}>
              {formInputs.input2 || valueName || address}
            </Box>

            <TextField
              placeholder="Apt/Suite/floor"
              name="input1"
              {...register("input1", { required: "This field is required" })}
              value={formInputs.input1}
              error={!!errors.input1} // Trigger error styling if there's an error
              helperText={errors.input1?.message} // Display error message if available
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                },
              }}
            />
            <TextField
              placeholder="Enter LandMark"
              name="input9"
              {...register("input9", { required: "This field is required" })}
              value={formInputs.input9}
              error={!!errors.input9} // Trigger error styling if there's an error
              helperText={errors.input9?.message} // Display error message if available
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                },
              }}
            />
            <TextField
              placeholder="Enter District or Town"
              name="input4"
              {...register("input4", { required: "This field is required" })}
              value={formInputs.input4}
              error={!!errors.input4} // Trigger error styling if there's an error
              helperText={errors.input4?.message} // Display error message if available
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                },
              }}
            />
            <TextField
              placeholder="Enter Postal Code"
              name="input5"
              {...register("input5", { required: "This field is required" })}
              value={formInputs.input5}
              error={!!errors.input5} // Trigger error styling if there's an error
              helperText={errors.input5?.message} // Display error message if available
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    borderColor: "black",
                    "& fieldset": {
                      borderColor: "black",
                    },
                  },
                },
              }}
            />
            <Grid item xs={12} sm={12}>
              <Box sx={{ fontWeight: "bold" }}>Choose Type</Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  my: 1,
                }}
              >
                <Box>
                  <IconButton
                    disableRipple
                    onClick={() => setSelectedLabel("house")}
                    sx={{
                      p: 1.5,
                      border: "1px solid #e7e6e6",
                      ".MuiSvgIcon-root": {
                        color:
                          selectedLabel === "house" ? "#df6a2d" : "#b7b7b7",
                        width: "25px",
                        height: "25px",
                      },
                    }}
                  >
                    <Home />
                  </IconButton>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", mt: 1 }}
                  >
                    Home
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    disableRipple
                    onClick={() => setSelectedLabel("work")}
                    sx={{
                      p: 1.5,
                      border: "1px solid #e7e6e6",
                      ".MuiSvgIcon-root": {
                        color: selectedLabel === "work" ? "#df6a2d" : "#b7b7b7",
                        width: "25px",
                        height: "25px",
                      },
                    }}
                  >
                    <Work />
                  </IconButton>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", mt: 1 }}
                  >
                    Work
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    disableRipple
                    onClick={() => setSelectedLabel("apartment")}
                    sx={{
                      p: 1.5,
                      border: "1px solid #e7e6e6",
                      ".MuiSvgIcon-root": {
                        color:
                          selectedLabel === "apartment" ? "#df6a2d" : "#b7b7b7",
                        width: "25px",
                        height: "25px",
                      },
                    }}
                  >
                    <BusinessIcon />
                  </IconButton>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", mt: 1 }}
                  >
                    Apartment
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Divider />

            <Box sx={{ fontWeight: "bold", mt: 1 }}>Drop-off-options</Box>

            <Box sx={{ flexGrow: 1, padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignItems:'center',
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img src={Images.dropoff} style={{width:"40px"}}/>
                  <Typography variant="body1" sx={{ ml: 2 ,fontWeight:"bold"}}>
                    {state.deliveryOption === "meet_door"
                      ? "Meet at my door"
                      : state.deliveryOption === "outside"
                      ? "Meet at outside"
                      : state.deliveryOption === "lobby"
                      ? "Meet in the lobby"
                      : state.leaveOption === "leave_door"
                      ? "Leave at door"
                      : state.leaveOption === "reception"
                      ? "Leave at reception"
                      : state.leaveOption === "buildingDoor"
                      ? "Leave at building door"
                      : ""}
                  </Typography>
                </Box>
                <button
                 className="edit-button"
                  variant="text"
                  onClick={() => {
                    setActiveScreen(3);
                  }}
                >
                  Edit
                </button>
              </Box>
            </Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ mt: 1 }}>
              <Box sx={{ fontWeight: "bold", mb: 1 }}>
                Instructions for Delivery Person
              </Box>
              <TextField
  multiline
  name="input6"
  {...register("input6", { required: "This field is required" })}
  value={formInputs.input6}
  error={!!errors.input6} // Trigger error styling if there's an error
  helperText={errors.input6?.message} // Display error message if available
  onChange={handleInputChange}
  minRows={3}
  placeholder="Example: I'll be on the corner"
  sx={{
    width: "100%",
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    resize: "none",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4", // No border by default
      },
      "&:hover fieldset": {
        borderColor: "#c4c4c4", // No border on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "black", // No border when focused
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#c4c4c4", // No border for notched outline
    },
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      borderBottom: "none", // Remove underline before and after
    },
  }}
/>

            </Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ mt: 1 }}>
              <Box sx={{ fontWeight: "bold", mb: 1 }}>Address Label</Box>
              <TextField
                placeholder="Address Label"
                name="input8"
                {...register("input8", { required: "This field is required" })}
                value={formInputs.input8}
                error={!!errors.input8} // Trigger error styling if there's an error
                helperText={errors.input8?.message} // Display error message if available
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      borderColor: "black",
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                  },
                }}
              />
            </Box>
            <Button
              type="submit"
              sx={{
                cursor: "pointer",
                background: Colors.black,
                color: Colors.white,
                width: "150px",
                p: 1.5,
                display: "flex",
                justifyContent: "center",
                margin: "20px auto",
                borderRadius: "10px",
                ":hover":{
                  background: Colors.black,
                  color: Colors.white,
                }
              }}
            >
              Save Changes
            </Button>
          </Box>
        </>
      )}

      {activeScreen === 3 && (
        <Box sx={{ p: 3 }}>
          <Accordion
            expanded={state.openAccordion === "handItToMe"}
            onChange={handleAccordionChange("handItToMe")}
            sx={{ border: state.openAccordion === "handItToMe" ?  "4px solid black":""}}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
             <Box sx={{display:"flex" ,alignItems:'center',gap:'15px'}}> <img src={Images.dropoff} style={{width:"40px"}}/><Typography sx={{fontWeight:"bold"}}>Hand it to me</Typography></Box> 
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <FormLabel component="legend">Choose delivery option</FormLabel>

                <RadioGroup
                  aria-label="delivery options"
                  name="delivery-options"
                  value={state.deliveryOption}
                  onChange={handleOptionChange}
                >
                  <FormControlLabel
                    value="meet_door"
                    control={<Radio />}
                    label="Meet at my door"
                  />
                  <FormControlLabel
                    value="outside"
                    control={<Radio />}
                    label="Meet outside"
                  />
                  <FormControlLabel
                    value="lobby"
                    control={<Radio />}
                    label="Meet in the lobby"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={state.openAccordion === "leaveAtLocation"}
            onChange={handleAccordionChange("leaveAtLocation")}
            sx={{ mt: 2   ,border: state.openAccordion === "leaveAtLocation" ?  "4px solid black":""}}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{display:"flex" ,alignItems:'center',gap:'15px'}}> <img src={Images.dropoff} style={{width:"40px"}}/><Typography sx={{fontWeight:"bold"}}>Leave at Location</Typography></Box> 

            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <FormLabel component="legend">Choose leave location</FormLabel>
                <RadioGroup
                  aria-label="leave at location"
                  name="leave-location"
                  value={state.leaveOption}
                  onChange={handleLeaveOptionChange}
                >
                  <FormControlLabel
                    value="leave_door"
                    control={<Radio />}
                    label="Leave at door"
                  />
                  <FormControlLabel
                    value="reception"
                    control={<Radio />}
                    label="Leave at reception"
                  />
                  <FormControlLabel
                    value="buildingDoor"
                    control={<Radio />}
                    label="Leave at building door"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
<Box sx={{display:"flex",justifyContent:"flex-end"}}>

          <Button variant="contained" onClick={handleUpdate} sx={{ mt: 2,background:Colors.black}}>
            Update
          </Button>
        </Box>
        </Box>
      )}
    </Box>
  );
};

export default LocationDetail;
