import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Checkbox,
  Radio,
  IconButton,
  Divider,
  Box,
} from "@mui/material";
import {
  Add,
  Remove,
  ArrowForward,
  ArrowBack,
  ArrowForwardIos,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedProductData,
  setMenuTree,
  popMenuTree,
  setRequiredList,
  removeRequiredList,
} from "../../../../redux/slices/CartSlice";
import Colors from "../../../../assets/styles";
import { ErrorToaster } from "../../../../components/Toaster";

const CheckBoxComponent = React.memo(
  ({
    item,
    selectedRadioOpt,
    setSelectedRadioOpt,
    listTitle,
    minValue,
    maxValue,
    requiredList,
    dispatch,
    navigateToSubMenu,
  }) => {
    const handleSelection = (element) => {
      let spreadselectedRadioOpt = { ...selectedRadioOpt };
      if (Object.keys(selectedRadioOpt).includes(listTitle)) {
        let selectedValues = [...selectedRadioOpt[listTitle]];
        const findItem = selectedValues.findIndex(
          (e) => e.ProductName === element.ProductName
        );

        if (findItem !== -1) {
          let sortElements = [...selectedValues];
          sortElements.splice(findItem, 1);
          if (sortElements.length === 0) {
            delete spreadselectedRadioOpt[listTitle];
          } else {
            spreadselectedRadioOpt[listTitle] = sortElements;
          }

          if (sortElements.length < minValue) {
            const requiredArray = [...requiredList];
            requiredArray.push(listTitle);
            dispatch(setRequiredList(requiredArray));
          }
          setSelectedRadioOpt(spreadselectedRadioOpt);
        } else if (selectedValues.length < maxValue) {
          selectedValues.push(element);
          spreadselectedRadioOpt[listTitle] = selectedValues;
          setSelectedRadioOpt(spreadselectedRadioOpt);
          if (element.SubGroups.length > 0) {
            navigateToSubMenu(element);
          }
        } else {
          ErrorToaster(`${listTitle} can be selected up to ${maxValue}`);
        }
      } else {
        spreadselectedRadioOpt[listTitle] = [element];
        setSelectedRadioOpt(spreadselectedRadioOpt);
        if (element.SubGroups.length > 0) {
          navigateToSubMenu(element);
        }
      }
      dispatch(removeRequiredList(listTitle));

    };

    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography>{item.ProductName}</Typography>
        <Typography>{item.price > 0 ? `+$${item.price}` : null}</Typography>
        <Checkbox
          checked={selectedRadioOpt[listTitle]?.some(
            (e) => e.ProductName === item.ProductName
          )}
          onChange={() => handleSelection(item)}
        />
        {item.SubGroups.length > 0 && <ArrowForward />}
      </Grid>
    );
  }
);

const QtyComponent = React.memo(
  ({
    item,
    selectedRadioOpt,
    setSelectedRadioOpt,
    listTitle,
    minValue,
    maxValue,
    qty,
    setQty,
    requiredList,
    dispatch,
    navigateToSubMenu,
  }) => {
    const handleSelection = (element, sumVal) => {
      let spreadselectedRadioOpt = { ...selectedRadioOpt };
      let groupQty = { ...qty };

      if (Object.keys(selectedRadioOpt).includes(listTitle)) {
        let selectedValues = [...selectedRadioOpt[listTitle]];
        const findItem = selectedValues.find(
          (e) => e.ProductName === element.ProductName
        );
        const findIndex = selectedValues.findIndex(
          (e) => e.ProductName === element.ProductName
        );

        if (findIndex !== -1) {
          if (sumVal === "increment" && groupQty[listTitle] < maxValue) {
            selectedValues[findIndex].qty += 1;
            groupQty[listTitle] += 1;
          } else if (
            sumVal === "decrement" &&
            selectedValues[findIndex].qty > minValue
          ) {
            selectedValues[findIndex].qty -= 1;
            groupQty[listTitle] -= 1;
          }
          spreadselectedRadioOpt[listTitle] = selectedValues;
        } else if (sumVal === "increment") {
          selectedValues.push({ ...element, qty: 1 });
          groupQty[listTitle] = 1;
          spreadselectedRadioOpt[listTitle] = selectedValues;
        }
      } else if (sumVal === "increment") {
        spreadselectedRadioOpt[listTitle] = [{ ...element, qty: 1 }];
        groupQty[listTitle] = 1;
      }

      setSelectedRadioOpt(spreadselectedRadioOpt);
      setQty(groupQty);
      if (element.SubGroups.length > 0 && sumVal === "increment") {
        navigateToSubMenu(element);
      }
      dispatch(removeRequiredList(listTitle));

    };

    return (
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography>{item.ProductName}</Typography>
        <Typography>{item.price > 0 ? `+$${item.price}` : null}</Typography>
        <Grid item>
          <IconButton
            onClick={() => handleSelection(item, "decrement")}
            disabled={qty[listTitle] <= minValue}
          >
            <Remove />
          </IconButton>
          <Typography>
            {selectedRadioOpt[listTitle]?.find(
              (e) => e.ProductName === item.ProductName
            )?.qty || 0}
          </Typography>
          <IconButton
            onClick={() => handleSelection(item, "increment")}
            disabled={qty[listTitle] >= maxValue}
          >
            <Add />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
);

// const RadioComponent = React.memo(
//   ({ item, selectedRadioOpt, setSelectedRadioOpt,parentName, listTitle, minValue, maxValue, requiredList, dispatch, navigateToSubMenu }) => {
//     const handleSelection = element => {
//       let spreadselectedRadioOpt = {...selectedRadioOpt};
//       console.log(spreadselectedRadioOpt)
//       if (Object.keys(selectedRadioOpt).includes(listTitle)) {
//         let listLength =
//           selectedRadioOpt[listTitle]?.length == undefined
//             ? 1
//             : selectedRadioOpt[listTitle]?.length + 1;
//         let selectedValues = [...selectedRadioOpt[listTitle]];
//         const findItem = selectedValues.findIndex(
//           e => e.ProductName == element.ProductName,
//         );
//         if (findItem !== -1) {
//         } else if (listLength >= minValue && listLength <= maxValue) {
//           selectedValues.push({...element, parentName});
//           spreadselectedRadioOpt[listTitle] = selectedValues;
//           setSelectedRadioOpt(spreadselectedRadioOpt);
//         } else {
//           let holdValues = [];
//           holdValues.push({...element, parentName});
//           spreadselectedRadioOpt[listTitle] = holdValues;
//           setSelectedRadioOpt(spreadselectedRadioOpt);
//         }
//       } else {
//         let holdValues = [];
//         holdValues.push({...element, parentName});
//         spreadselectedRadioOpt[listTitle] = holdValues;
//         setSelectedRadioOpt(spreadselectedRadioOpt);
//       }

//       // talha test
//       if (element?.SubGroups?.length > 0) {
//         const requiredArray = [...requiredList];
//         element?.SubGroups?.forEach(element => {
//           if (element.Required) {
//             requiredArray.push(element.GroupName);
//           }
//         });
//         dispatch(setRequiredList(requiredArray));

//         var obj = JSON.stringify({
//           data: element.SubGroups,
//           productName: element.ProductName,
//         });
//         dispatch(setMenuTree(obj));
//         navigateToSubMenu(element);

//         console.log("setMenuTree",obj)

//       }

//       // *For Remove Id in required list
//       dispatch(removeRequiredList(listTitle));

//     }

//     {console.log("swsvwsv",selectedRadioOpt[listTitle]?.some(
//       (e) => e.ProductName === item.ProductName
//     ))}
//       return (
//         <Box
//           key={item?.item?.ProductName}  // Ensure unique key
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             cursor: 'pointer',
//             p: 1,
//             justifyContent: 'space-between',
//           }}
//         >
//           <Box sx={{ flex: 0.8 }}>
//             <Typography variant="subtitle1" fontWeight="bold">
//               {item.ProductName}
//             </Typography>
//             {item.price > 0 && (
//               <Typography variant="body2" sx={{ mt: 1 }}>
//                 +${item.price}
//               </Typography>
//             )}
//           </Box>

//           <Box sx={{ display: 'flex', alignItems: 'center' }} >
//             <Radio
//               checked={selectedRadioOpt[listTitle]?.some(
//                 (e) => e.ProductName === item.ProductName
//               )}
//               color="primary"
//               onClick={() => handleSelection(item)}
//               onChange={() => handleSelection(item)}
//             />
//             {item.SubGroups.length > 0 ? (
//               <ArrowForwardIos sx={{ ml: 1 }} />
//             ) : (
//               <Box sx={{ width: 20, height: 20 }} />
//             )}
//           </Box>
//         </Box>
//       );

//   }
// );

const RadioComponent = React.memo(
  ({
    item,
    selectedRadioOpt,
    setSelectedRadioOpt,
    parentName,
    listTitle,
    minValue,
    maxValue,
    requiredList,
    dispatch,
    navigateToSubMenu,
  }) => {
    const handleSelection = (element) => {
      let spreadselectedRadioOpt = {...selectedRadioOpt};
      if (Object.keys(selectedRadioOpt).includes(listTitle)) {
        let listLength =
          selectedRadioOpt[listTitle]?.length == undefined
            ? 1
            : selectedRadioOpt[listTitle]?.length + 1;
        let selectedValues = [...selectedRadioOpt[listTitle]];
        const findItem = selectedValues.findIndex(
          e => e.ProductName == element.ProductName,
        );
        if (findItem !== -1) {
        } else if (listLength >= minValue && listLength <= maxValue) {
          selectedValues.push({...element, parentName});
          spreadselectedRadioOpt[listTitle] = selectedValues;
          setSelectedRadioOpt(spreadselectedRadioOpt);
        } else {
          let holdValues = [];
          holdValues.push({...element, parentName});
          spreadselectedRadioOpt[listTitle] = holdValues;
          setSelectedRadioOpt(spreadselectedRadioOpt);
        }
      } else {
        let holdValues = [];
        holdValues.push({...element, parentName});
        spreadselectedRadioOpt[listTitle] = holdValues;
        setSelectedRadioOpt(spreadselectedRadioOpt);
      }

      // talha test
      if (element?.SubGroups?.length > 0) {
        const requiredArray = [...requiredList];
        element?.SubGroups?.forEach(element => {
          if (element.Required) {
            requiredArray.push(element.GroupName);
          }
        });
        dispatch(setRequiredList(requiredArray));

        var obj = JSON.stringify({
          data: element.SubGroups,
          productName: element.ProductName,
        });

        dispatch(setMenuTree(obj));
        navigateToSubMenu(element);
      }

      dispatch(removeRequiredList(listTitle));
    };

    const handleBoxClick = (event) => {
      event.stopPropagation();
      handleSelection(item);
    };

    return (
      <Box
        onClick={handleBoxClick}
        key={item.ProductName}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          p: 1,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ flex: 0.8 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {item.ProductName}
          </Typography>
          {item.price > 0 && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              +${item.price}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Radio
            checked={
              selectedRadioOpt[listTitle]?.[0]?.ProductName === item.ProductName
            }
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              handleSelection(item);
            }}
          />
          {item.SubGroups.length > 0 ? (
            <ArrowForwardIos sx={{ ml: 1 }} />
          ) : (
            <Box sx={{ width: 20, height: 20 }} />
          )}
        </Box>
      </Box>
    );
  }
);


const OrderProductItems = ({
  open,
  onClose,
  productName,
  data,
  setOpenModal,
}) => {
  const selectedData = useSelector((state) => state.cartData.selectedData);
  const requiredList = useSelector((state) => state.cartData.required);
  const menuTree = useSelector((state) => state.cartData.menuTree);
  const parentListName = useSelector((state) => state.cartData.parentListName);

  const dispatch = useDispatch();

  const [isRequired, setIsRequired] = useState(true);
  const [qty, setQty] = useState({});
  const [currentStep, setCurrentStep] = useState(0); // Track current step
  const [currentData, setCurrentData] = useState(data); // Track data for current step
  const [currentProductName, setCurrentProductName] = useState(productName);

  useEffect(() => {
    const checkRequired = () => {
      const required = currentData.some(
        (item) => item.Required && !selectedData[item.GroupName]
      );
      setIsRequired(required);
    };

    checkRequired();
  }, [selectedData, currentData]);

  const handleCheckSelections = () => {
    onClose();
    setOpenModal(false);
    console.log(open);
    if (menuTree?.length == 1) {
      dispatch(popMenuTree(null));
    } else {
      // navigation.navigate(
      //   'orderProductItems',
      console.log(menuTree?.[menuTree?.length - 1]);
      // );
      dispatch(popMenuTree(null));
    }
    onClose();
  };

  const navigateToSubMenu = (item) => {
    setCurrentData(item.SubGroups);
    setCurrentProductName(item.ProductName);
    setCurrentStep(1);
  };

  const goBack = () => {
    if (currentStep === 1) {
      setCurrentData(data);
      setCurrentProductName(productName);
      setCurrentStep(0);
    } else {
      console.log(menuTree?.[menuTree?.length - 1]);
      onClose();
    }
  };

  const renderListItem = (item) => {
    let groupQty = { ...qty };
    if (
      item.Minimum >= 0 &&
      item.Maximum > 1 &&
      item.NoOfTimes > 1 &&
      Object.keys(selectedData).includes(item.GroupName)
    ) {
      let tQty = 0;
      selectedData[item.GroupName]?.forEach((element) => {
        tQty += element?.qty ? element?.qty : 0;
      });
      groupQty[item.GroupName] = tQty;
    }
    return (
      <React.Fragment key={item.GroupName}>
        <Typography variant="h6">{item.GroupName}</Typography>
        {item.Required && (
          <Typography color="error" variant="subtitle2">
            Required
          </Typography>
        )}
        <Divider />
        {item.Minimum === 1 && item.Maximum === 1 && item.NoOfTimes === 1
          ? item.Properties.map((subItem, subIndex) => (
              <RadioComponent
                // parentId={item?.item?.$id}
                listTitle={item.GroupName}
                minValue={item.Minimum}
                maxValue={item.Minimum}
                parentName={parentListName}
                item={subItem}
                selectedRadioOpt={selectedData}
                setSelectedRadioOpt={(e) => dispatch(setSelectedProductData(e))}
                data={data}
                navigateToSubMenu={navigateToSubMenu}
                dispatch={dispatch}
                requiredList={requiredList}
                // handleRemoveRequired={(id) => handleRemoveRequired(id)}
              />
            ))
          : item.Maximum > 1
          ? item.Properties.map((subItem, subIndex) => (
              <CheckBoxComponent
                // parentId={item?.item?.$id}
                item={subItem}
                listTitle={item.GroupName}
                minValue={item.Minimum}
                maxValue={item.Maximum}
                selectedRadioOpt={selectedData}
                setSelectedRadioOpt={(e) => dispatch(setSelectedProductData(e))}
                navigateToSubMenu={navigateToSubMenu}
                dispatch={dispatch}
                requiredList={requiredList}
                // totalCalcPrice={totalCalcPrice}
                // setTotalCalcPrice={setTotalCalcPrice}
              />
            ))
          : item.Properties.map((subItem, subIndex) => (
              <QtyComponent
                // parentId={item?.item?.$id}
                listTitle={item.GroupName}
                minValue={item.Minimum}
                maxValue={item.Maximum}
                selectedRadioOpt={selectedData}
                item={subItem}
                setSelectedRadioOpt={(e) => dispatch(setSelectedProductData(e))}
                navigateToSubMenu={navigateToSubMenu}
                setQty={setQty}
                qty={groupQty}
                dispatch={dispatch}
                requiredList={requiredList}
                // totalCalcPrice={totalCalcPrice}
                // setTotalCalcPrice={setTotalCalcPrice}
              />
            ))}
      </React.Fragment>
    );
  };

  return (
    <>
      <Box>
        {currentStep === 1 && (
          <>
            <Box onClick={goBack} sx={{cursor:"pointer"}}>
              <IconButton>
                <ArrowBack />
              </IconButton>
              {currentProductName}
            </Box>
          </>
        )}

        <Box sx={{ mt: 1, fontWeight: "bold" }}>
          {currentData.map((item, index) => renderListItem(item))}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
          <Button
            onClick={handleCheckSelections}
            sx={{
              color: Colors.white,
              background: Colors.black,
              ":hover": {
                background: Colors.black,
              },
            }}
            disabled={isRequired}
          >
            Done
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default OrderProductItems;
