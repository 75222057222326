import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Switch,
  IconButton,
  TextField,
  CircularProgress,
  Modal,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ArrowBackIos } from '@mui/icons-material';
import moment from 'moment';
import { profileApiCall } from '../../../../api/ProfileServices';
import { setIsWallet, setPaymentCardData } from '../../../../redux/slices/CartSlice';
import Colors from '../../../../assets/styles';
import AddPaymentMethodModal from '../Payment/AddPaymentMethodModal';
import CybersourceAuthenticationWebViewModal from '../Payment/CybersourceDeviceAuthenticationModal';
import CybersourceDeviceAuthenticationModal from "../Payment/CybersourceDeviceAuthenticationModal";
import SimpleDialog from '../../../../components/Dialog';
import { paymentApiCall } from '../../../../api/PaymentServices';
import CartServices from '../../../../api/CartServices';
import { ErrorToaster ,SuccessToaster} from '../../../../components/Toaster';
import {giftCardApiCall} from "../../../../api/giftCard/index"
import TwoButtonModal from '../../../../components/TwoButtonModal';
import WalletIcon from "@mui/icons-material/Wallet";
import {CreditCard} from "@mui/icons-material";


const GiftCheckout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state;
  const [paymentOptionModal, setPaymentOptionModal] = useState(false);
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState(false);

  const [sendNow, setSendNow] = useState(true);
  const { control, getValues, handleSubmit } = useForm();
  const [sendByMsg, setSendByMsg] = useState(true);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const userData = useSelector(state => state.userData.userData);
  const paymentCardData = useSelector(state => state.cartData.paymentCardData);

  const [walletBalance, setWalletBalance] = useState(0);
  const [usedWalletBalance, setUsedWalletBalance] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [customerCards, setCustomerCards] = useState([]);

  const [removeLoader, setRemoveLoader] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const [paymentAuthUrl, setPaymentAuthUrl] = useState("");
  const [paymentAuthToken, setPaymentAuthToken] = useState("");

  const [deviceAuthModal, setDeviceAuthModal] = useState(false);
  const [loadDeviceCheck, setLoadDeviceCheck] = useState(true);
  const [paymentAuthModal, setPaymentAuthModal] = useState(false);
  const [removeCardModal, setRemoveCardModal] = useState(false);
  const [paymentMethodOptionModal, setPaymentMethodOptionModal] =useState(false);

  const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);

  const [addCardModal, setAddCardModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  const isWallet = useSelector((state) => state.cartData.isWallet);
  const userSelectedAddress = useSelector(
    (state) => state.userData.selectedAddress
  );
  const userAddress = useSelector(
    state => state.userData.selectedAddress,
  );
  console.log(useSelector((state) => state.cartData));
  const userIpAddress = useSelector((state) => state.userData.userIpAddress);

  const [isWalletEnabled, setIsWalletEnabled] = useState(isWallet);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    getWalletBalance();
    getCustomerCards();
    calculateOrderTotal();
  }, [data, isWallet, walletBalance, usedWalletBalance]);

  // const payerAuthenticationCheckEnrollmentService = async (params) => {
  //   console.warn("Payer Auth check");
  //   console.warn(params);
  //   try {
  //     const res = await CartServices.payerAuthenticationCheckEnrollmentService(
  //       params
  //     );
  //     console.warn(res);
  //     // console.warn('Enrollment Response', res);

  //     if (res?.status == "AUTHENTICATION_FAILED") {
  //       ErrorToaster("Authentication failed");
  //       setPlacingOrder(false);
  //     } else if (res?.status == "PENDING_AUTHENTICATION") {
  //       // call enrollment then validate then payment than place order
  //       await setLoadDeviceCheck(false);
  //       await setPaymentAuthToken(
  //         res?.consumerAuthenticationInformation?.accessToken
  //       );
  //       await setPaymentAuthUrl(
  //         res?.consumerAuthenticationInformation?.stepUpUrl
  //       );
  //       setTimeout(() => {
  //         setPaymentAuthModal(true);
  //       }, 1000);
  //     } else if (res?.status == "AUTHENTICATION_SUCCESSFUL") {
  //       // call payment and then place order
  //       // console.warn('Authentication successful', res);
  //       var params = {
  //         user_id: userData?.id,
  //         transaction_id:
  //           res?.consumerAuthenticationInformation?.authenticationTransactionId,
  //       };
  //       // Process payment from customer
  //       payment3dHandler(
  //         orderTotal,
  //         // 1,
  //         res?.consumerAuthenticationInformation?.authenticationTransactionId
  //       );
  //     } else if (res == null || res == undefined) {
  //       setPlacingOrder(false);
  //       ErrorToaster(
  //         "There is an issue with your Payment Information. Contact Support."
  //       );
  //     }
  //   } catch (error) {
  //     setPlacingOrder(false);
  //     console.warn("error", error);
  //   }
  // };
  const configurePayment = formData => {
    setPlacingOrder(true);
    setShowPaymentConfirmation(true);
  };
  // const payerAuthenticationValidationService = async (obj) => {
  //   try {
  //     const res = await CartServices.payerAuthenticationValidationService(obj);

  //     console.warn("validate Authentication", res);

  //     if (res?.status == "AUTHENTICATION_SUCCESSFUL") {
  //       console.warn("Payment 3d handler 1244 called.");
  //       payment3dHandler(
  //         orderTotal,
  //         // 1,
  //         res?.consumerAuthenticationInformation?.authenticationTransactionId
  //       );
  //     } else {
  //       ErrorToaster("Authentication failed, please try again.");
  //       setPlacingOrder(false);
  //     }
  //   } catch (error) {
  //     console.warn("validate auth error", error);
  //     setPlacingOrder(false);
  //   }
  // };


  const getPaymentCardHandler = async () => {
    try {
      let result = await paymentApiCall.getCards({ user_id: userData?.id });
      // console.warn('payment Cart ', result);
      console.log(
        "🚀 ~ file: index.js:1144 ~ getPaymentCardHandler ~ result:",
        result
      );

      if (result.length < 1) {
        dispatch(setPaymentCardData({}));
      } else {
        dispatch(setPaymentCardData(result?.[0]));
        setCards(result);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: PaymentOptionModal.js:28 ~ getPaymentCardHandler ~ error:",
        error
      );
    } finally {
    }
  };
  const checkoutGiftCard = async (paymentId) => {
    setLoading(true);
    setPlacingOrder(true);
    console.warn(isWallet);
    console.warn(paymentCardData?.last_four);
    try {
      let payMode;
      if (usedWalletBalance > 0 && orderTotal > 0) {
        payMode = 'CW';
      } else if (usedWalletBalance > 0 && orderTotal <= 0) {
        payMode = 'WALLET';
      } else if (usedWalletBalance <= 0 && orderTotal > 0) {
        payMode = 'CARD';
      }
      let obj = {
        giftCardId: data?.giftCardId,
        schedule: sendNow ? new Date() : scheduleDate,
        is_now: sendNow,
        payment_mode: payMode,
        payable: orderTotal,
        gross: subTotal,
        payment_id:
          isWallet && usedWalletBalance > 0 && orderTotal <= 0
            ? '0'
            : paymentId,
        wallet_amount: usedWalletBalance,
        userIpAddress: userIpAddress,
        customerActualLatlng:
          currentLocation?.latitude + ',' + currentLocation?.longitude,
      };
      if (!sendByMsg) {
        obj.user_email = sendNow ? getValues('email') : getValues('recipent');
      }
      console.log(obj);
      const Result = await giftCardApiCall.updateGiftCard(obj);
      console.warn(Result);
      setPlacingOrder(false);

      SuccessToaster('Gift card purchase successfully');
      if (sendNow && sendByMsg) {
        navigate('/my-gift'
        //   , {
        //   data: {
        //     toName: data?.toName,
        //     fromName: data?.fromName,
        //     senderNote: data?.senderNote,
        //     giftAmount: data?.giftAmount,
        //     giftData: data?.giftData,
        //     giftCardCode: data?.giftCardCode,
        //     isShare: true,
        //     notGoBack: false,
        //   },
        // }
      )
        ;
      } else {
      navigate('/my-gift');
      }
    } catch (error) {
      setPlacingOrder(false);
      console.log(
        '🚀 ~ file: giftCheckout.js:49 ~ checkoutGiftCard ~ error:',
        error,
      );
    } finally {
      setLoading(false);
    }
  };
  const getOneTimeLocation = async () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by this browser.");
      return; // Stop the function if geolocation is not supported.
    }
  
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        console.log("Position obtained:", position);
        setCurrentLocation({ latitude, longitude });
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.error("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.error("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.error("The request to get user location timed out.");
            break;
          default:
            console.error("An unknown error occurred.", error);
            break;
        }
      },
      { timeout: 10000 } // Optional: Set a timeout for the location request.
    );
  };

  useEffect(()=>{
    getOneTimeLocation()
  },[])
  const setupPayerAuth = async (obj) => {
    var params = {
      user_id: userData.id,
      amount: orderTotal,
      card_id: obj?.card_id,
      currency: "KYD",
    };
    console.warn(params);

    try {
      const res = await CartServices.setupPayerAuth(params);
      // console.warn('Setup Payment AUTH', res);
      console.warn(res);
      if (res?.status == "0") {
        ErrorToaster(res?.message);
        setPlacingOrder(false);
        return;
      }
      if (res?.consumerAuthenticationInformation) {
        await setPaymentAuthUrl(
          res?.consumerAuthenticationInformation?.deviceDataCollectionUrl
        );
        await setPaymentAuthToken(
          res?.consumerAuthenticationInformation?.accessToken
        );
        await setReferenceId(
          res?.consumerAuthenticationInformation?.referenceId
        );
        await setDeviceAuthModal(true);
      }
    } catch (error) {
      setPlacingOrder(false);
      console.warn("error setup payer auth", error);
      ErrorToaster(error);
    }
  };
  const payment3dHandler = async (cardAmount) => {
  
    // let obj = {
    //   user_id: userData?.id,
    //   amount: cardAmount,
    //   card_id: paymentCardData?.id,
    //   currency: "KYD",
    //   user_address_id: userSelectedAddress?.id,
    //   transaction_id: transaction_id,
    //   userIpAddress: userIpAddress,
    // };
    console.log("🚀 ~ file: index.js:1121 ~ payment3dHandler ~ obj:", cardAmount);
    try {
      let result = await paymentApiCall.pay3d(cardAmount);
      console.warn(result);
      if (result?.$id == "1") {
        checkoutGiftCard(result?.data?.["transaction-id"]);
      } else {
        SuccessToaster("" + result?.status);
        setPlacingOrder(false);
      }
    } catch (error) {
      setPlacingOrder(false);

      console.warn(
        "🚀 ~ file: index.js:1120 ~ payment3dHandler ~ error:",
        error
      );
    } finally {
      // setLoader(false);
    }
  };

  const removeCardHandler = async (cardID) => {
    try {
      let result = await paymentApiCall.deleteCard(cardID);
      getPaymentCardHandler();
      setRemoveCardModal(false);
      setAddPaymentMethodModal(true);
    } catch (error) {
      console.log(
        "🚀 ~ file: AddPaymentMethodModal.js:37 ~ removeCardHandler ~ error:",
        error
      );
    } finally {
    }
  };
  const getCustomerCards = async () => {
    try {
      const result = await profileApiCall.getCustomerCards({ user_id: userData?.id });
      setCustomerCards(result);
      if (result?.length > 0) {
        dispatch(setPaymentCardData(result[0]));
      }
    } catch (error) {
      console.error('Error fetching customer cards:', error);
    }
  };

  const getWalletBalance = async () => {
    try {
      const result = await profileApiCall.getWalletDetails({ user_id: userData?.id });
      setWalletBalance(result);
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
    }
  };

  const calculateOrderTotal = () => {
    let subtotal = data?.giftAmount;
    setSubTotal(subtotal.toFixed(2));
    let walletUsage = 0;

    if (isWallet) {
      if (walletBalance > subtotal) {
        walletUsage = subtotal;
      } else {
        walletUsage = walletBalance;
      }
    }
    setUsedWalletBalance(walletUsage.toFixed(2));
    setOrderTotal(parseFloat(subtotal - walletUsage).toFixed(2));
  };


 

  const handleDateChange = (newValue) => {
    setScheduleDate(newValue);
    setDatePickerOpen(false);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 10 }}>
      <TwoButtonModal
        pressCancelBtn={() => {
          setShowPaymentConfirmation(false);
          setPlacingOrder(false);
        }}
        modalVisible={showPaymentConfirmation}
        dispatch={() => {
          setShowPaymentConfirmation(false);
          if (isWallet && usedWalletBalance > 0 && orderTotal <= 0) {
            checkoutGiftCard(null);
          } else {
            let obj = {
              user_id: userData?.id,
              amount: orderTotal,
              card_id: paymentCardData?.id,
              currency: "KYD",
              user_address_id: userAddress?.id,
            };
            payment3dHandler(obj)
            // setupPayerAuth(obj);
             // temporarily closing this
            // payment3dHandler(data?.giftAmount, 'transaction');
          }
        }}
        text={
          //   'Are you sure you want create a Gift Card of $' +
          //   data?.giftAmount +
          //   `. Mode of payment is
          //   isWallet
          //     ? 'Wallet.'
          //     : 'Card.`
          // }
          'Are you sure you want create a Gift Card of $' + data?.giftAmount
        }
        successBtnText={'Yes'}
        rejectBtnText={'No'}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h5" fontWeight="bold">
          Checkout
        </Typography>
      </Box>

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Box>
          <Typography variant="h6">When would you like to send it?</Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <Button
               
                sx={{
                    background:sendNow ? Colors.black :Colors.grey,
                    color:sendNow ? Colors.white :Colors.black,
                    ":hover":{
                        background:sendNow ? Colors.black :Colors.grey,

                    }
                  }}
                onClick={() => setSendNow(true)}
              >
                Now
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                    background:!sendNow ? Colors.black :Colors.grey,
                    color:!sendNow ? Colors.white :Colors.black,
                    ":hover":{
                        background:!sendNow ? Colors.black :Colors.grey,

                    }
                  }}
                onClick={() => {
                  setSendNow(false);
                  setSendByMsg(false);
                }}
              >
                Schedule
              </Button>
            </Grid>
          </Grid>
        </Box>

        {sendNow ? (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">How would you like to send it?</Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item>
                <Button
                //   variant={sendByMsg ? 'contained' : 'outlined'}
                  onClick={() => setSendByMsg(true)}
                  sx={{
                    background:sendByMsg ? Colors.black :Colors.grey,
                    color:sendByMsg ? Colors.white :Colors.black,
                    ":hover":{
                        background:sendByMsg ? Colors.black :Colors.grey,

                    }
                  }}
                >
                  Message
                </Button>
              </Grid>
              <Grid item>
                <Button
                 sx={{
                    background:!sendByMsg ? Colors.black :Colors.grey,
                    color:!sendByMsg ? Colors.white :Colors.black,
                    ":hover":{
                        background:!sendByMsg ? Colors.black :Colors.grey,

                    }
                  }}
                  onClick={() => setSendByMsg(false)}
                >
                  Email
                </Button>
              </Grid>
            </Grid>
            {!sendByMsg && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">What's the recipient's Email?</Typography>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Recipient's email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Please enter a valid email address',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      placeholder="name@example.com"
                      variant="outlined"
                      error={!!field.errors}
                      helperText={field.errors ? field.errors.message : ''}
                      sx={{ mt: 2 }}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">What's the recipient's Email?</Typography>
            <Controller
              name="recipient"
              control={control}
              rules={{
                required: "Recipient's email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter a valid email address',
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder="Enter the recipient's email"
                  variant="outlined"
                  error={!!field.errors}
                  helperText={field.errors ? field.errors.message : ''}
                  sx={{ mt: 2 }}
                />
              )}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <Button
                variant="outlined"
                onClick={() => setDatePickerOpen(true)}
               
                sx={{
                    mr:2,
                    background :Colors.black,
                    color :Colors.white,
                    ":hover":{
                        background: Colors.black,

                    }
                  }}
              >
                {scheduleDate ? 'Change' : 'Schedule'}
              </Button>
              {scheduleDate && (
                <Typography variant="body1">
                  {moment(scheduleDate).format('LLLL')}
                </Typography>
              )}
            </Box>
          </Box>
        )}

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Payment</Typography>
          <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <WalletIcon/>
              <Box sx={{ ml: 2 }}>
                <Typography variant="body1">Pay from wallet</Typography>
                <Typography variant="body2">
                  Balance: ${walletBalance.toFixed(2)}
                </Typography>
                <Switch
                  checked={isWallet}
                  onChange={(e) => dispatch(setIsWallet(e.target.checked))}
                  color="primary"
                />
              </Box>
            </Box>
            {isWallet && usedWalletBalance > 0 && (
              <Typography variant="body2" sx={{ color: 'green' }}>
                Using ${usedWalletBalance} from wallet balance
              </Typography>
            )}
          </Paper>

          {!isWallet || orderTotal > 0 ? (
            <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
               <CreditCard/>
                <Box sx={{ ml: 2, flex: 1 }}>
                  <Typography variant="body1">
                    {paymentCardData?.last_four
                      ? ` ${paymentCardData.last_four}`
                      : 'Select Payment Method'}
                  </Typography>
                  {isWallet && orderTotal > 0 && (
                    <Typography variant="body2" sx={{ color: 'red' }}>
                      Your wallet does not have sufficient balance for this order. The remaining balance will be charged to your card.
                    </Typography>
                  )}
                </Box>
                <Button variant="outlined" sx={{   
                    background :Colors.black,
                    color :Colors.white,
                    ":hover":{
                        background: Colors.black,

                    }}} onClick={() => setPaymentOptionModal(true)}>
                  Change
                </Button>
              </Box>
            </Paper>
          ) : null}
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Summary</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">From: {data?.fromName}</Typography>
            <Typography variant="body1">To: {data?.toName}</Typography>
            {data?.senderNote && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Sender Note: {data?.senderNote}
              </Typography>
            )}
            <Typography variant="body1" sx={{ mt: 2 }}>
              Subtotal: ${subTotal}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Total: ${orderTotal}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Button
            sx={{
                background:Colors.black ,
                color:Colors.white,
                ":hover":{
                    background:Colors.black ,

                }
              }}
            color="primary"
            fullWidth
            onClick={handleSubmit(configurePayment)}
            disabled={placingOrder}
          >
            {placingOrder ? <CircularProgress sx={{color:'white'}} size={24} /> : `Order Gift Card $${orderTotal}`}
          </Button>
        </Box>
      </Paper>

      <Modal
  open={datePickerOpen}
  onClose={() => setDatePickerOpen(false)}
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  
>
  <Box
    sx={{
      padding: "86px 90px 86px 121px",
      backgroundColor: 'white',
      borderRadius: 1,
      minWidth: 300,
      borderRadius:"20px",
      boxShadow: 24,
    }}
  >
    <Typography variant="h6" sx={{ marginBottom: 2}}>
      Select Date
    </Typography>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        renderInput={(props) => <TextField {...props} fullWidth />}
        value={scheduleDate}
        onChange={handleDateChange}
      />
    </LocalizationProvider>
  </Box>
</Modal>
{paymentOptionModal && (
        <SimpleDialog
          open={paymentOptionModal}
          onClose={() => setPaymentOptionModal(false)}
          title="Add Payment Methods"
        >
          <AddPaymentMethodModal
            modalVisible={addPaymentMethodModal}
            setIsWalletEnabled={setIsWalletEnabled}
            setClose={() => setAddPaymentMethodModal(false)}
            fromWallet={false}
            onClick={() => {
              setAddCardModal(true);
              setAddPaymentMethodModal(false);
            }}
            // loader={loader}
            removeLoader={removeLoader}
            removeCard={removeCardHandler}
            cards={cards}
            setAddPaymentMethodModal={() =>
              setAddPaymentMethodModal(!addPaymentMethodModal)
            }
            removeModal={removeCardModal}
            setRemoveModal={setRemoveCardModal}
            closeAllModal={() => {
              setPaymentOptionModal(false);
              setPaymentMethodOptionModal(false);
              setAddPaymentMethodModal(false);
            }}
          />
        </SimpleDialog>
      )}

      {/* {deviceAuthModal && (
        <CybersourceDeviceAuthenticationModal
          modalVisible={deviceAuthModal}
          setClose={() => setDeviceAuthModal(false)}
          authToken={paymentAuthToken}
          url={paymentAuthUrl}
          loadDeviceCheck={loadDeviceCheck}
          onSuccess={(type) => {
            console.warn("device authentication modal type:", type);
            console.warn("device authentication modal data:", type);

            if (type == "DEVICE_AUTHENTICATION") {
              setDeviceAuthModal(false);
              let obj = {
                user_id: userData?.id,
                amount: orderTotal,
                card_id: paymentCardData?.id,
                // address: userAddress?.map_address,
                user_address_id: userSelectedAddress?.id,
                //  reference_id: referenceId,
                currency: "KYD",
              };
              payerAuthenticationCheckEnrollmentService(obj);
            }
          }}
          onFailure={() => {
            setPlacingOrder(false);
          }}
          onBackPress={() => {
            setDeviceAuthModal(false);
            setPlacingOrder(false);
          }}
        />
      )} */}
      {/* {paymentAuthModal && (
        <CybersourceAuthenticationWebViewModal
          modalVisible={paymentAuthModal}
          setClose={() => setPaymentAuthModal(false)}
          onPress={() => setPaymentAuthModal(true)}
          authToken={paymentAuthToken}
          url={paymentAuthUrl}
          loadDeviceCheck={loadDeviceCheck}
          onSuccess={(type, data) => {
            setPaymentAuthModal(false);
            console.warn("authentication modal type:", type);
            console.warn("authentication modal data", data);
            if (type == "DEVICE_AUTHENTICATION") {
              // console.warn(data?.Key);
              var params = {
                user_id: userData?.id,
                transaction_id: data?.Key,
              };
              payerAuthenticationValidationService(params);
            }
          }}
          onFailure={() => {}}
          onBackPress={() => {
            setPaymentAuthModal(false);
          }}
        />
      )} */}
    </Container>
  );
};

export default GiftCheckout;
