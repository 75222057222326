import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  IconButton,
  Grid,
  Skeleton,
  Container,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import SearchStoreCard from "../../../../components/SearchStoreCard";
import TwoButtonModal from "../../../../components/TwoButtonModal";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIosNew } from "@mui/icons-material";
import Colors from "../../../../assets/styles";
import StoreServices from "../../../../api/StoreServices";
import { favoriteApiCall } from "../../../../api/favorite";

const SearchStore = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const userAddress = useSelector((state) => state.userData.selectedAddress);

  const [shopList, setShopList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [listLoader, setListLoader] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [noRestaurantFound, setNoRestaurantFound] = useState(false);

  const storeID = state?.storeID;
  const storeType = state?.storeType;
  const headerTitle = state?.headerTitle;

  const sleep = () => new Promise((r) => setTimeout(r, 1000));

  useEffect(() => {
    // Scroll to top on pathname change
    window.scrollTo(0, 0);
  }, [state]);

  const getHomeDataHandler = async () => {
    setListLoader(true);
    try {
      await sleep();
      const param = {
        day: new Date().getDay(),
        time: new Date().getHours(),
        storeID,
        storeType,
        user_id: userData?.id,
      };
      const result = await StoreServices.getGroceryStores(param);
      setShopList(result);
      setFilteredList(result);
      setListLoader(false);
    } catch (error) {
      console.error("Error fetching store data:", error);
      setListLoader(false);
    }
  };

  useEffect(() => {
    getHomeDataHandler();
  }, [storeID, storeType]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value.length === 0) {
      setFilteredList(shopList);
    } else {
      const filtered = shopList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  const handleAddToFavorite = async (shopId, status) => {
    try {
      await favoriteApiCall.addToFavorite(userData.id, shopId, "store", status);
    } catch (error) {
      console.error("Error adding to favorites:", error);
    }
  };

  return (
    <Container sx={{ maxWidth: "1200px" }}>
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper", minHeight: "100vh" }}>
        <TwoButtonModal
          open={loginModal}
          onClose={() => setLoginModal(false)}
          onConfirm={() => {
            setLoginModal(false);
            navigate("/login");
          }}
          text="You are not logged in. Please log in to continue"
          confirmText="Login"
          cancelText="Close"
        />

        <Box sx={{ p: 2 }}>
          <Typography variant="h6">{headerTitle || "Grocery"}</Typography>

          <Box  sx={{ display: "flex", alignItems: "center", mt: {md:2,sm:3,xs:6} }}>
            <IconButton onClick={() => navigate(-1)} sx={{ background: Colors.grey, borderRadius: "50%", mr: 2 }}>
              <ArrowBackIosNew />
            </IconButton>
            </Box>
            {listLoader ? (
           
           <Grid item xs={12} sm={12} md={12} >
             <Skeleton variant="rectangular" sx={{borderRadius:"30px",mt:2}} width="100%" height={60} />
           </Grid>
        
  
     ) : (
            <TextField
            fullWidth
              sx={{
              mt:2,
                "fieldset": {
                  borderRadius: "30px",
                },
              }}
              placeholder="Search stores"
              value={searchQuery}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchQuery && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearchQuery("")}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
            />
            )}
          </Box>

          <Grid container spacing={3} sx={{ mt: 2 }}>
            {listLoader ? (
              Array.from(new Array(6)).map((_, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Skeleton variant="rectangular" sx={{ borderRadius: "10px" }} width="100%" height={180} />
                  <Skeleton variant="rectangular" sx={{ borderRadius: "10px", mt: 1 }} width="70%" height={30} />
                  <Skeleton variant="rectangular" sx={{ borderRadius: "10px", mt: 1 }} width="20%" height={30} />
                </Grid>
              ))
            ) : filteredList.length > 0 ? (
              filteredList.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <SearchStoreCard
                    data={item}
                    addToFavorite={handleAddToFavorite}
                    navigate={() =>
                      navigate(`/shop/detail/${item?.shopid}`, {
                        state: {
                          shopId: item.shopid,
                          basedOn: "store",
                          typeId: item.store_type[0]?.storeType_id,
                        },
                      })
                    }
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50vh",
                  }}
                >
                  <Typography sx={{ textAlign: "center" }}>
                    No Store found
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      
    </Container>
  );
};

export default SearchStore;
