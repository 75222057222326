import React, { useEffect, useState } from "react";
import {
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
  Motorcycle as MotorcycleIcon,
  Fastfood as FastfoodIcon,
  AttachMoney as AttachMoneyIcon,
  LocationOn as LocationOnIcon,
  AccessTime as AccessTimeIcon,
  CreditCard as CreditCardIcon,
  CalendarToday as CalendarTodayIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  ErrorRounded,
} from "@mui/icons-material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./Checkout.css";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Images } from "../../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  ListItem,
  Divider,
  TextField,
  Button,
  Card,
  Switch,
} from "@mui/material";
import SimpleDialog from "../../../components/Dialog";
import Location from "../../../components/Location/index";
import { useDispatch, useSelector } from "react-redux";
import CartServices from "../../../api/CartServices";
import {
  setIsWallet,
  setOnlineCartData,
  setPaymentCardData,
} from "../../../redux/slices/CartSlice";
import Colors from "../../../assets/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  setAppliedPromoCode,
  setCartCounter,
} from "../../../redux/slices/UserDataSlices";
import Promotions from "../pages/Promotion";
import moment from "moment";
import { useForm } from "react-hook-form";
import LocationAddressServices from "../../../api/LocationAddressServices";
import CybersourceDeviceAuthenticationModal from "../../../views/admin/pages/Payment/CybersourceDeviceAuthenticationModal";
import WalletIcon from "@mui/icons-material/Wallet";
import { profileApiCall } from "../../../api/ProfileServices";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import { paymentApiCall } from "../../../api/PaymentServices";
import PaymentList from "../../../components/PaymentList";
import PaymentOptionModal from "../pages/Payment/PaymentOptionModal";
import AddPaymentMethodModal from "../pages/Payment/AddPaymentMethodModal";
import CybersourceAuthenticationWebViewModal from "../pages/Payment/CybersourceAuthenticationWebViewModal";
import TwoButtonModal from "../../../components/TwoButtonModal";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { styled } from "@mui/system";
import BreadCrumbProvider from '../../../hooks/useBreadCrumb';


const ToggleSlider = styled(Box)(({ transformX, selectedType }) => ({
  transition: "transform 300ms ease-in-out",
  transform: `translateX(${transformX}%)`,
  position: "absolute",
  width: selectedType === 'Pickup' ? "50%" : "50%",

  height: "100%",
  backgroundColor: "#ffffff", // Slider color
  borderRadius: "20px", // Rounded corners for slider
}));

const ToggleButtonContainer = styled(Box)({
  display: "flex",
  position: "relative",
  width: "215px", // Set a fixed width for the container
  height: "40px",
  borderRadius: "20px",
  backgroundColor: "#f0f0f0", // Background color for non-active area
  overflow: "hidden",
  border: "2px solid #aeaeae47", // Border color adjusted for visibility
});

const ToggleOption = styled(Button)(({ active }) => ({
  flex: 1,
  height: "100%",
  borderRadius: "20px",
  backgroundColor: "transparent", // Remove background color here
  color: "black", // Black text for all states
  "&:hover": {
    backgroundColor: "rgba(255,255,255,0.3)", // Light hover effect
  },
}));

const ToggleButton = ({
  selectedType,
  setSelectedType,
  isTakeaway,
  deliveryDisabled,
  pickupDisabled,
}) => {
  const [transformX, setTransformX] = useState(0);

  useEffect(() => {
    if (pickupDisabled) {
      setTransformX(0);
    } else if (deliveryDisabled) {
      setTransformX(50); // 50% of the container width to right side
    } else {
      setTransformX(selectedType === "Pickup" ? 100 : 0); // Correctly aligns with the options
    }
  }, [pickupDisabled, deliveryDisabled, selectedType]);

  return (
    <>
      <Box>
        <Box>
          <ToggleButtonContainer>
            <ToggleSlider transformX={transformX} selectedType={selectedType} />
            <ToggleOption
              disabled={deliveryDisabled}
              active={selectedType === "Delivery"}
              onClick={() => setSelectedType("Delivery")}
            >
              Delivery
            </ToggleOption>
            <ToggleOption
              disabled={pickupDisabled || !isTakeaway}
              active={selectedType === "Pickup"}
              onClick={() => setSelectedType("Pickup")}
            >
              Pickup
            </ToggleOption>
          </ToggleButtonContainer>
        </Box>
      </Box>
    </>
  );
};

const CheckoutPage = () => {
  const [selectedOption, setSelectedOption] = useState("standard");
  const [dialog, setDialog] = useState(false);
  const [promotiondialog, setPromotionDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isTakeaway, setIsTakeaway] = useState(true);
  const [cartData, setCartData] = useState([]);
  const [serveOption, setServeOption] = useState("");
  const [deliveryDisabled, setDeliveryDisabled] = useState(true);
  const [pickupDisabled, setPickupDisabled] = useState(true);
  const [selectedType, setSelectedType] = useState("");
  const [selectedDeliveryType, setSelectedDeliveryType] = useState("Standard");
  const [quantities, setQuantities] = useState({});
  const [itemTotal, setItemTotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [calculatingTotal, setCalculatingTotal] = useState(true);
  const [promocodeValidity, setPromoCodeValidity] = useState(true);
  const [validityText, setValidityText] = useState("");
  const [promoCodeDiscount, setPromocodeDiscount] = useState(0);
  const [priorityTotal, setPriorityTotal] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [distanceFee, setDistanceFee] = useState(0);
  const [distancePFee, setDistancePFee] = useState(0);
  const [smallOrderFee, setSmallOrderFee] = useState(0);
  const [isDeliveryFree, setIsDeliveryFree] = useState(false);
  const [deliveryPFee, setDeliveryPFee] = useState(0);
  const [usedWalletBalance, setUsedWalletBalance] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [reloadCalc, setReloadCalc] = useState(false);
  const [addCardModal, setAddCardModal] = useState(false);
  const [selectedShopAddress, setSelectedShopAddress] = useState("");
  const [cards, setCards] = useState([]);
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState(false);
  const [removeCardModal, setRemoveCardModal] = useState(false);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [paymentOptionModal, setPaymentOptionModal] = useState(false);
  const [paymentMethodOptionModal, setPaymentMethodOptionModal] =
    useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [paymentAuthUrl, setPaymentAuthUrl] = useState("");
  const [paymentAuthToken, setPaymentAuthToken] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [deviceAuthModal, setDeviceAuthModal] = useState(false);
  const [loadDeviceCheck, setLoadDeviceCheck] = useState(true);
  const [paymentAuthModal, setPaymentAuthModal] = useState(false);
  const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(-1);
  const [showAddressCnfrmModal, setShowAddressCnfrmModal] = useState(false);
  const [addressConfirmed, setAddressConfirmed] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [currentLocation, setCurrentLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [distanceBtwRestaurant, setDistanceBtwRestaurant] = useState(0);
  const { getOrderDetail } = BreadCrumbProvider()

  const navigate = useNavigate();

  const handleSwitchChange = (event) => {
    const isEnabled = event.target.checked;
    console.log(isEnabled);
    setIsWalletEnabled(isEnabled);
    dispatch(setIsWallet(isEnabled));
  };

  console.log(cartData);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const toggleDropdown = () => setIsOpen(!isOpen);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log(state);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleSelectOption = (option) => {
    setSelectedDeliveryType(option);
  };
  // reducer data
  const userSelectedAddress = useSelector(
    (state) => state.userData.selectedAddress
  );
  const userData = useSelector((state) => state.userData.userData);
  console.log(userSelectedAddress);

  const appliedPromoCode = useSelector(
    (state) => state.userData.appliedPromoCode
  );
  const isWallet = useSelector((state) => state.cartData.isWallet);
  console.log(useSelector((state) => state.cartData));
  const [isWalletEnabled, setIsWalletEnabled] = useState(isWallet);
  const paymentCardData = useSelector(
    (state) => state.cartData.paymentCardData
  );

  const userIpAddress = useSelector((state) => state.userData.userIpAddress);

  const [tipOptions, setTipOptions] = useState([
    { label: "10%", value: 0.1, selected: false },
    { label: "20%", value: 0.2, selected: false },
    { label: "30%", value: 0.3, selected: false },
    { label: "40%", value: 0.4, selected: false },
    { label: "Other", value: 0, selected: false },
  ]);

  const [customTip, setCustomTip] = useState(0);
  const [othersSelected, setOthersSelected] = useState(false);

  const handlePress = (index) => {
    const newTipOptions = tipOptions.map((option, i) => ({
      ...option,
      selected: i === index ? !option.selected : false,
    }));

    setOthersSelected(index === tipOptions.length - 1 && !othersSelected);
    setTipOptions(newTipOptions);

    if (index !== tipOptions.length - 1) {
      // If a percentage option is pressed, set the custom tip to the calculated value
      const selectedPercentage = tipOptions[index].value;
      setCustomTip((itemTotal * selectedPercentage).toFixed(2).toString());
    } else {
      // If "Others" is pressed, clear the custom tip
      setCustomTip(0);
    }
    if (newTipOptions.every((option) => !option.selected)) {
      setCustomTip(0);
    }
  };
  //Tip Change
  const handleCustomTipChange = (event) => {
    const amount = event.target.value;
    const newTipOptions = [...tipOptions];
    const otherIndex = newTipOptions.findIndex(
      (option) => option.label === "Other"
    );
    newTipOptions[otherIndex].value = parseFloat(amount) || 0;
    setTipOptions(newTipOptions);
    console.log(tipOptions);
    if (/^\d*$/.test(amount)) {
      // setCustomTip(amount);
    }
  };
  //Add Tip
  const handleAddTip = () => {
    const selectedTip = tipOptions.find((option) => option.selected == true);

    if (selectedTip) {
      let tipAmount = 0;

      if (selectedTip.label === "Other") {
        tipAmount = selectedTip.value || 0;
      } else {
        tipAmount = itemTotal * selectedTip.value;
      }
      setCustomTip(tipAmount);
      console.log("Tip Amount:", tipAmount);
    }
  };
  //Get Cart Data
  const getCartData = async () => {
    try {
      // if (state?.fromReorder) {
      //   setCartData(state?.itemData);       
      // } else {
      const result = await CartServices.getCartDetails(userData?.id);
      setCartData(result[state.itemIndex]);
      if (!result || result.length === 0) {
        console.log("Cart is empty or result is undefined.");
        return false;
      }
      if (state.fromReorder == true) {
        checkShopServeOption(result[state.itemIndex]);
        const initialQuantities = {};
        const currentShopCart = result[state.itemIndex];
        if (
          !currentShopCart ||
          !currentShopCart.items ||
          currentShopCart.items.length === 0
        ) {
          console.log("No items available in the current shop cart.");
          return false;
        }
        currentShopCart.items.forEach((item, itemIndex) => {
          initialQuantities[`${state.itemIndex}-${itemIndex}`] = item.quantity;
          console.log(
            `Setting initial quantity for ${state.itemIndex}-${itemIndex}: ${item.quantity}`
          );
        });

        setQuantities(initialQuantities);

        dispatch(setCartCounter(currentShopCart.items.length));
        dispatch(setOnlineCartData(currentShopCart.items));

        if (currentShopCart.items.length === 0) {
          console.log("empty");
        }
        return;
      } else {

        checkShopServeOption(result[state.itemIndex]);
        console.log(result[state.itemIndex]);

        const initialQuantities = {};
        const currentShopCart = result[state.itemIndex];

        if (
          !currentShopCart ||
          !currentShopCart.items ||
          currentShopCart.items.length === 0
        ) {
          console.log("No items available in the current shop cart.");
          return false;
        }

        // Set quantities based on items in the current shop's cart

        currentShopCart.items.forEach((item, itemIndex) => {
          initialQuantities[`${state.itemIndex}-${itemIndex}`] = item.quantity;
          console.log(
            `Setting initial quantity for ${state.itemIndex}-${itemIndex}: ${item.quantity}`
          );
        });

        setQuantities(initialQuantities);

        dispatch(setCartCounter(currentShopCart.items.length));
        dispatch(setOnlineCartData(currentShopCart.items));

        if (currentShopCart.items.length === 0) {
          console.log("empty");
        }
        return;
      }
      // }
    } catch (error) {
      console.log("file: index.js:37 => getCartData => error:", error);
    } finally {
      // Any cleanup or additional logic can go here
    }
  };
  //Check Promo Code
  const checkPromoCodeValidity = (itemTotal) => {
    console.log(itemTotal);
    // Check promo code validity for minimum order amount
    // console.log(cartData);
    if (
      appliedPromoCode?.[0]?.minOrderAmount != null ||
      appliedPromoCode?.[0]?.minOrderAmount != undefined
    ) {
      if (itemTotal < appliedPromoCode?.[0]?.minOrderAmount) {
        setPromoCodeValidity(false);
        setValidityText(
          "This order amount is less than the minimum required for the promo code."
        );
        setPromocodeDiscount(0);
        return 0;
      } else if (itemTotal > appliedPromoCode?.[0]?.maxOrderAmount) {
        setPromoCodeValidity(false);
        setValidityText("This order amount exceeds the maximum order limit.");
        setPromocodeDiscount(0);
        return 0;
      } else if (appliedPromoCode?.[0]?.shop_id != null) {
        if (
          appliedPromoCode?.[0]?.shop_id == cartData?.shop_id ||
          appliedPromoCode?.[0]?.shop_id == 0
        ) {
          setPromoCodeValidity(true);
          setValidityText("This promotion is valid");
          return calculatePromoCode(itemTotal);
        } else {
          setValidityText("This promotion is not valid for this restaurant.");
          setPromoCodeValidity(false);
          setPromocodeDiscount(0);
          return 0;
        }
      } else if (appliedPromoCode?.[0]?.shop_id == null) {
        // check validity of promo code for this shop
        setPromoCodeValidity(true);
        setValidityText("This promotion is valid");
        return calculatePromoCode(itemTotal);
      } else {
        setPromoCodeValidity(true);
        setValidityText("This promotion is valid");
        return calculatePromoCode(itemTotal);
      }
    } else {
      setPromoCodeValidity(true);
      setValidityText("This promotion is valid");
      return calculatePromoCode(itemTotal);
    }
  };
  // Calculate Promo Code Value
  const calculatePromoCode = (itemTotal) => {
    // console.log(appliedPromoCode?.[0]);

    if (
      appliedPromoCode?.[0]?.promocode_type?.toLowerCase() == "amount" ||
      appliedPromoCode?.[0]?.percentageType?.toLowerCase() == "amount"
    ) {
      setPromocodeDiscount(appliedPromoCode?.[0]?.discount);
      return appliedPromoCode?.[0]?.discount;
      // return appliedPromoCode?.[0]?.discount;
    } else if (
      appliedPromoCode?.[0]?.promocode_type == "percent" ||
      appliedPromoCode?.[0]?.percentageType?.toLowerCase() == "percent"
    ) {
      // setPromocodeDiscount(appliedPromoCode?.[0]?.discount);
      // this.promocodeDiscount = (this.promocodeDiscount / 100) * this.itemTotal;
      var priorityValue =
        selectedDeliveryType == "Priority" ? priorityTotal : 0;

      var leTotal =
        parseFloat(customTip) +
        parseFloat(deliveryFee) +
        parseFloat(distanceFee) +
        parseFloat(smallOrderFee) +
        parseFloat(priorityValue);

      console.log("lets eat total is", leTotal);
      console.log("tip", customTip);
      console.log("deliveryFee", deliveryFee);
      console.log("distanceFee", distanceFee);
      console.log("smallOrderFee", smallOrderFee);
      console.log("priorityValue", priorityValue);
      // console.log(leTotal);
      setPromocodeDiscount(
        ((appliedPromoCode?.[0]?.discount / 100) * leTotal).toFixed(2)
      );
      return ((appliedPromoCode?.[0]?.discount / 100) * leTotal).toFixed(2);
      // return (promoCodeDiscount / 100) * leTotal;
    } else if (appliedPromoCode?.length == 0) {
      setPromocodeDiscount(0);
      return 0;
    }
    // calculateOrderTotal();
  };
  //Calculate Order Total
  const calculateOrderTotal = () => {
    // check promo code

    try {
      setCalculatingTotal(true);

      var itemtotalTemp = 0;
      // setItemTotal(0);
      if (cartData?.members?.length > 0) {
        cartData?.members[0]?.items.forEach((element) => {
          itemtotalTemp += element.lntotal;
        });
      } else {
        cartData?.items.forEach((element) => {
          itemtotalTemp += element.lntotal;
        });
      }
      console.log("itemtotalTemp", itemtotalTemp);

      const promoCodeAmount = checkPromoCodeValidity(itemtotalTemp);
      setItemTotal(itemtotalTemp);
      var priorityValue = 0;

      if (
        cartData?.smallorderfees != undefined &&
        cartData?.smallorderfees?.length > 0
      ) {
        if (itemtotalTemp < cartData?.smallorderfees[0]?.total) {
          setSmallOrderFee(cartData?.smallorderfees?.[0]?.fee);
          // setReloadCalc(!reloadCalc);
        }
      }
      if (isDeliveryFree) {
        console.log("dliveryFee");
        setDeliveryFee(0);
      }
      // if (selectedType == 'Delivery') {
      //   priorityValue = selectedDeliveryType == 'Priority' ? priorityTotal : 0;
      // }
      console.log(customTip);
      var subtotal =
        parseFloat(itemtotalTemp) +
        parseFloat(customTip) +
        parseFloat(smallOrderFee) -
        parseFloat(promoCodeAmount);

      console.log(subTotal);
      if (selectedType == "Delivery") {
        console.log("first selectType");
        if (selectedDeliveryType == "Priority") {
          console.log("priority");
          subtotal += deliveryPFee + distancePFee;
        } else {
          console.log("elsepriority");
          subtotal += deliveryFee + distanceFee;
        }
      }

      setSubTotal(subtotal.toFixed(2));

      if (isWallet) {
        if (walletBalance > subtotal) {
          setUsedWalletBalance(subtotal?.toFixed(2));
        } else {
          setUsedWalletBalance(walletBalance?.toFixed(2));
          // old app login to use wallet and card both but we dont allow
          // setUsedWalletBalance(0.0);
        }
      } else {
        setUsedWalletBalance(0.0);
      }
      console.log("subtotal", subTotal);
      console.log("used wallet balance", usedWalletBalance);
      var total = subtotal - usedWalletBalance;
      if (total < 0) {
        total = total * -1;
      }
      setOrderTotal(total.toFixed(2));
      // setOrderTotal(parseFloat(subtotal - usedWalletBalance).toFixed(2));
      setCalculatingTotal(false);
    } catch (error) {
      setCalculatingTotal(false);
    }
  };
  // get wallet baalance
  const getWalletBalance = async () => {
    try {
      const result = await profileApiCall.getWalletDetails({
        user_id: userData?.id,
      });

      setWalletBalance(result);

      console.log("My wallet Balance", result);
    } catch (error) {
      ErrorToaster(error);
      console.log("file: index.js:getWalletBalance => error:", error);
    }
  };
  //Check Shop Sever Option
  const checkShopServeOption = (cartData) => {
    console.log(cartData);
    if (cartData?.takeway == 0 || cartData?.takeway == undefined) {
      setIsTakeaway(false);
      console.log(cartData?.takeway);
    } else {
      setIsTakeaway(true);
    }
    setServeOption(cartData?.pd_status);
    checkRestaurantDeliveryStatus(cartData?.pd_status);
  };
  //Check Restaurant Delivery
  const checkRestaurantDeliveryStatus = (serveOption) => {
    console.log("I am called check restaurant delivery Status", serveOption);
    if (serveOption != null && serveOption != undefined) {
      if (serveOption == "pickup") {
        if (!isTakeaway) {
          setPickupDisabled(true);
          setSelectedType("Delivery");
        } else {
          console.log(" i am called else pickup");
          setPickupDisabled(false);
          setDeliveryDisabled(true);
          setSelectedType("Pickup");
        }
      } else if (serveOption == "delivery") {
        setPickupDisabled(true);
        setDeliveryDisabled(false);
        setSelectedType("Delivery");
      } else {
        setPickupDisabled(false);
        setDeliveryDisabled(false);
        setSelectedType("Delivery");
      }
    }
  };

  useEffect(() => {
    calculateOrderTotal();
    console.log("first");
  }, [
    customTip,
    smallOrderFee,
    reloadCalc,
    selectedDeliveryType,
    cartData,
    isWallet,
    appliedPromoCode,
    usedWalletBalance,
    walletBalance,
  ]);

  const renderPromo = () => {
    const discount = appliedPromoCode?.[0]?.discount;
    const promocodeType = appliedPromoCode?.[0]?.promocode_type;
    const percentageType = appliedPromoCode?.[0]?.percentageType?.toLowerCase();

    let discountMessage = "";

    if (discount) {
      const isPercent =
        promocodeType === "percent" || percentageType === "percent";
      const discountValue = isPercent ? discount : discount.toFixed(2);
      const unit = isPercent ? "%" : "$";
      const savingsMessage = isPercent
        ? `${discountValue}${unit} on Let's Eat charges`
        : `${unit}${discountValue} on Let's Eat charges`;

      discountMessage = `You’re saving ${savingsMessage}`;
    }

    console.log(discountMessage);

    return discountMessage;
  };

  // for increament
  const handleIncrement = (shopIndex, itemIndex) => {
    const key = `${shopIndex}-${itemIndex}`;
    const newQty = quantities[key] + 1;
    updateCart(shopIndex, itemIndex, newQty);
  };
  // for decreament
  const handleDecrement = (shopIndex, itemIndex) => {
    const key = `${shopIndex}-${itemIndex}`;
    const currentQty = quantities[key];
    if (currentQty > 1) {
      const newQty = currentQty - 1;
      updateCart(shopIndex, itemIndex, newQty);
    } else {
      handleDeleteItem(cartData?.items?.[itemIndex]?.cart_id);
    }
  };
  //for delete item
  const handleDeleteItem = async (id) => {
    try {
      const result = await CartServices.removeProductFromCart({ id });
      console.log("handleDeleteItem result:", result);
      await getCartData();
    } catch (error) {
      console.log("handleDeleteItem error:", error);
    }
  };
  // update cart
  const updateCart = async (shopIndex, itemIndex, qty) => {
    try {
      const ingredientsArray = [];
      const selectedProduct = cartData?.items?.[itemIndex];

      selectedProduct?.itemIngs.forEach((element) => {
        let ingredientObj = {
          ingredient_prop_id: element?.ingredient_prop_id,
          modifier: element?.modifier,
          value: element?.value ? element?.value * qty : 1 * qty,
        };
        ingredientsArray.push(ingredientObj);
      });

      const editCart = {
        id: selectedProduct?.cart_id,
        user_id: userData?.id,
        shop_address_id: 0,
        shop_id: selectedProduct?.shop_id,
        product_id: selectedProduct?.product_id,
        quantity: qty,
        note: "",
        ingredients: ingredientsArray,
      };

      const result = await CartServices.updateAddToCart(editCart);
      console.log("updateCart result:", result);

      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [`${shopIndex}-${itemIndex}`]: qty,
      }));
      await getCartData();
    } catch (error) {
      console.log("updateCart error:", error);
    } finally {
      console.log("updateCart finished");
      getCartData();
    }
  };

  const checkDeliveryFee = () => {
    setIsDeliveryFree(false);
    var day = moment(new Date()).format("ddd");
    console.log(cartData);
    if (
      cartData?.shoptimings != undefined &&
      cartData?.shoptimings != null &&
      cartData?.shoptimings?.length > 0
    ) {
      cartData?.shoptimings?.forEach((days) => {
        if (days.day == day.toUpperCase()) {
          if (days.timings != undefined) {
            days.timings.forEach((times) => {
              var currentTime = moment();

              if (
                times.free_del_start_time == undefined ||
                times.free_del_start_time == null
              ) {
                return false;
              } else if (
                times.free_del_start_time != undefined &&
                times.free_del_start_time != null &&
                times.free_del_end_time != undefined &&
                times.free_del_end_time != null
              ) {
                if (
                  isTimeBetween(
                    times.free_del_start_time,
                    times.free_del_end_time,
                    moment(currentTime).format("HH:mm")
                  )
                ) {
                  setIsDeliveryFree(false);
                }
              }
            });
          }
        } else if (days.day == "ALL") {
          if (days.timings != undefined) {
            days.timings.forEach((times) => {
              var currentTime = moment();
              // console.log(
              //   'CURRENT TIME: ' + moment(currentTime).format('HH:mm'),
              // );
              if (
                times.free_del_start_time == undefined ||
                times.free_del_start_time == null
              ) {
                // console.log('all freedel : false');
                return false;
              } else if (
                times.free_del_start_time != undefined &&
                times.free_del_start_time != null &&
                times.free_del_end_time != undefined &&
                times.free_del_end_time != null
              ) {
                if (
                  isTimeBetween(
                    times.free_del_start_time,
                    times.free_del_end_time,
                    moment(currentTime).format("HH:mm")
                  )
                ) {
                  setIsDeliveryFree(true);
                }
              }
            });
          }
        }
      });
    }
  };
  const isTimeBetween = (startTime, endTime, deviceTime) => {
    let start = moment(startTime, "H:mm");
    let end = moment(endTime, "H:mm");
    let server = moment(deviceTime, "H:mm");
    if (end < start) {
      return (
        (server >= start && server <= moment("23:59:59", "h:mm:ss")) ||
        (server >= moment("0:00:00", "h:mm:ss") && server < end)
      );
    }
    return server >= start && server < end;
  };

  const updateFee = (delFee, distFee, delPFee, distPFee) => {
    if (selectedType == "Delivery") {
      setDistancePFee(
        distPFee
          ? Number(parseFloat(distPFee).toFixed(2)) +
          Number(parseFloat(distFee).toFixed(2))
          : 0
      );
      setDeliveryPFee(delPFee ? delPFee + delFee : 0);
      setDistanceFee(distFee ? Number(parseFloat(distFee).toFixed(2)) : 0);
      setDeliveryFee(delFee ? delFee : 0);
    }
    setReloadCalc(!reloadCalc);
    var normalDelTotal = (delFee ? delFee : 0) + distanceFee;
    var priorityDelTotal = (delPFee ? delPFee : 0) + distancePFee;
    setPriorityTotal(priorityDelTotal);
  };
  const getShopAddresses = async (lat, long) => {
    try {
      const res =
        cartData?.based_on == "shop"
          ? await LocationAddressServices.getShopAddress({
            shop_id: cartData?.shop_id,
            origin_lat: lat,
            origin_lng: long,
          })
          : await LocationAddressServices.getStoreAddress({
            shop_id: cartData?.shop_id,
            origin_lat: lat,
            origin_lng: long,
          });

      if (res != null) {
        var shopAddressList = res;
        const closest = shopAddressList.reduce((acc, loc) =>
          acc.miles < loc.miles ? acc : loc
        );
        setSelectedShopAddress(closest);

        for (var i = 0; i < cartData?.distancefee.length; i++) {
          if (closest.miles <= cartData?.distancefee[i].miles) {
            var delFee = cartData?.distancefee[i].fee;
            var delPFee = cartData?.distancefee[i].pfee;
            updateFee(delFee, 0, delPFee, 0);
            break;
          } else {
            var delFee = cartData?.distancefee[i].fee;
            var delPFee = cartData?.distancefee[i].pfee;
            var overmiles = closest.miles - cartData?.distancefee[i].miles;
            var distFee = overmiles * cartData?.distancefee[i].permile;
            var distPFee = overmiles * cartData?.distancefee[i].permile_pfee;
            updateFee(delFee, distFee, delPFee, distPFee);
          }
        }
      }
      // setCartData(CartDetail);
    } catch (error) {
      console.warn("file: index.js:259 => getShopAddress => error:", error);
      // errorToaster(error);
    } finally {
    }
  };

  const getPaymentCardHandler = async () => {
    try {
      let result = await paymentApiCall.getCards({ user_id: userData?.id });
      // console.warn('payment Cart ', result);
      console.log(
        "🚀 ~ file: index.js:1144 ~ getPaymentCardHandler ~ result:",
        result
      );

      if (result.length < 1) {
        dispatch(setPaymentCardData({}));
      } else {
        dispatch(setPaymentCardData(result?.[0]));
        setCards(result);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: PaymentOptionModal.js:28 ~ getPaymentCardHandler ~ error:",
        error
      );
    } finally {
    }
  };
  
  const removeCardHandler = async (cardID) => {
    try {
      let result = await paymentApiCall.deleteCard(cardID);
      getPaymentCardHandler();
      setRemoveCardModal(false);
      setAddPaymentMethodModal(true);
    } catch (error) {
      console.log(
        "🚀 ~ file: AddPaymentMethodModal.js:37 ~ removeCardHandler ~ error:",
        error
      );
    } finally {
    }
  };


  const payment3dHandler = async (cardAmount) => {
    // setLoader(true);
    // setPlacingOrder(true);
    // console.warn(paymentCardData);
    // let obj = {
    //   user_id: userData?.id,
    //   amount: cardAmount,
    //   card_id: paymentCardData?.id,
    //   currency: "KYD",
    //   user_address_id: userSelectedAddress?.id,
    //   userIpAddress: userIpAddress,
    // };
    // setupPayerAuth(obj);
    console.log("🚀 ~ file: index.js:1121 ~ payment3dHandler ~ obj:", cardAmount);
    try {
      let result = await paymentApiCall.pay3d(cardAmount);
      // console.warn('Payment Response', result);
      // Setup payer auth cybersource
      console.log(result.status , "result");
      if (result?.status == true) {
        placeOrder(result?.data?.["transaction-id"]);
        SuccessToaster(result?.message);
    } else {
        ErrorToaster(result?.message);
        setPlacingOrder(false);
      }
    } catch (error) {
      setPlacingOrder(false);

      console.warn(
        "🚀 ~ file: index.js:1120 ~ payment3dHandler ~ error:",
        error
      );
    } finally {
      // setLoader(false);
    }
  };

  const getOneTimeLocation = async () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by this browser.");
      return; // Stop the function if geolocation is not supported.
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        console.log("Position obtained:", position);
        setCurrentLocation({ latitude, longitude });
      },
      (error) => {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.error("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.error("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.error("The request to get user location timed out.");
            break;
          default:
            console.error("An unknown error occurred.", error);
            break;
        }
      },
      { timeout: 10000 } // Optional: Set a timeout for the location request.
    );
  };

  // place order
  const placeOrder = async (paymentId) => {
    console.log(paymentId);
    const instruction = userSelectedAddress?.instructions;

    let payMode;
    if (usedWalletBalance > 0 && orderTotal > 0) {
      setPaymentMode("CW");
      payMode = "CW";
    } else if (usedWalletBalance > 0 && orderTotal <= 0) {
      setPaymentMode("WALLET");
      payMode = "WALLET";
    } else if (usedWalletBalance <= 0 && orderTotal > 0) {
      payMode = "CARD";
      setPaymentMode("CARD");
    }

    var paymentRef = "WALLET";

    var delivery_charge = 0;
    var extra_miles_fee = 0;
    var priority = 0;
    if (selectedType == "Delivery") {
      delivery_charge =
        selectedDeliveryType == "Priority"
          ? priorityTotal + deliveryFee
          : deliveryFee;
      extra_miles_fee =
        selectedDeliveryType == "Priority"
          ? distancePFee.toFixed(2)
          : distanceFee.toFixed(2);

      // distanceFee;
      priority = selectedDeliveryType == "Priority" ? 1 : 0;
    }

    var currentTime = moment();

    var orderobj = {
      user_id: userData?.id,
      based_on: state.based_on ? state?.based_on : "shop",
      user_address_id: selectedType == "Delivery" ? userSelectedAddress?.id : 0,
      shop_id: cartData?.shop_id ? cartData?.shop_id : state?.itemData?.shop_id,
      takeaway: selectedType != "Delivery" ? 1 : 0,
      payment_id:
        isWallet && usedWalletBalance > 0 && orderTotal <= 0 ? "0" : paymentId,
      note: "" + instruction,
      delivery_date: moment(currentTime).format("YYYY-MM-DD HH:mm:ss"),
      status: "ORDERED",
      schedule_status: 0,
      order_ready_status: 0,
      dispute: "NODISPUTE",
      created_at: moment(currentTime).format("YYYY-MM-DD HH:mm:ss"),
      tips: parseFloat(customTip).toFixed(2),
      shop_address_id: selectedShopAddress.shop_address_id,
      delivery_charge: delivery_charge,
      wallet_amount: usedWalletBalance,
      promocode_id: promoCodeDiscount ? appliedPromoCode?.[0]?.promocode_id : 0,
      promocode_amount: promoCodeDiscount,
      payable: orderTotal,
      gross: subTotal,
      origin: 'web',
      pm: payMode,
      card_id: orderTotal > 0 ? paymentCardData?.id : 0,
      utensilsrequired: true,
      small_order_fee: selectedType == "Delivery" ? smallOrderFee : 0,
      extra_miles_fee: extra_miles_fee,
      isfree: isDeliveryFree,
      pnpresponse: null, 
      priority: priority,
      fromNewApp: true,
      is_reorder: state?.fromReorder,
      reorder_id: state?.itemData?.orderid,
      userIpAddress: userIpAddress,
      customerActualLatlng:
        currentLocation?.latitude + "," + currentLocation?.longitude,
    };
    console.log("🚀 ~ file: index.js:934  ~ orderobj:", orderobj);
    console.log(orderobj);

    setPlacingOrder(true);

    try {
      const res = await CartServices.placeOrder(orderobj);
      console.log("🚀 ~ file: index.js:933  ~ res:", res);
      setPlacingOrder(false);
      if (res != null) {
        if (res.status == "0") {
          return false;
        } else if (res.status == "1") {
          SuccessToaster("Order has been placed");
          var orderdetails = {
            orderNo: res.id,
            orderType: "ongoing",
            takeaway: selectedType == "Delivery" ? 0 : 1,
          };

          dispatch(setAppliedPromoCode([]));

          navigate("/order-details", { state: res?.id });
          await getOrderDetail()
        } else {
          ErrorToaster("Something went wrong!");
        }
      }
    } catch (error) {
      console.warn(error);
      setPlacingOrder(false);
      ErrorToaster(error?.message);
      console.log("file: index.js:780  => error:", error?.response);
    } finally {
      setPlacingOrder(false);
    }
  };
  //submit order
  const submitOrder = () => {
    console.warn(selectedType);
    try {
      if (selectedType === "") {
        ErrorToaster("Choose Delivery/Pick up option");
        return false;
      }

      if (selectedType === "Delivery" && Object.values(userSelectedAddress).length === 0) {
        ErrorToaster("Select delivery address");
        console.log(Object.values(userSelectedAddress).length);
        return false;
      } else if (
        selectedType === "Delivery" &&
        selectedShopAddress.miles_upto > 0 &&
        selectedShopAddress.miles > selectedShopAddress.miles_upto
      ) {
        ErrorToaster(
          "Sorry, this restaurant does not currently deliver to your location at this time. Don’t worry though, you can go back and order from another restaurant!"
        );
        return false;
      }

      calculateOrderTotal();

      // pending work to do for payment gateway
      if (orderTotal > 0 && paymentCardData?.last_four === undefined) {
        ErrorToaster("Select card");
        return false;
      }

      // this is pending work to do
      if (selectedType === "Delivery" && !addressConfirmed) {
        console.log(addressConfirmed);
        console.log(userSelectedAddress);
        setShowAddressCnfrmModal(true);
      } else if (selectedType === "Delivery" && addressConfirmed) {
        setShowAddressCnfrmModal(false);
        setPlacingOrder(true);
        let amount;
        if (smallOrderFee > 0) {
          amount = cartData?.total_pay + cartData?.smallorderfees[0]?.fee;
        } else {
          amount = cartData?.total_pay;
        }

        if (isWallet && usedWalletBalance > 0 && orderTotal <= 0) {
          placeOrder();
        } else {
          let obj = {
            user_id: userData?.id,
            amount: orderTotal,
            card_id: paymentCardData?.id,
            currency: "KYD",
            user_address_id: userSelectedAddress?.id,
          };
          console.warn("setup payer auth obj");
        //   setupPayerAuth(obj);
        }
      } else if (selectedType === "Pickup") {
        setShowAddressCnfrmModal(false);
        setAddressConfirmed(true);
        setPlacingOrder(true);
        let amount;
        if (smallOrderFee > 0) {
          amount = cartData?.total_pay + cartData?.smallorderfees[0]?.fee;
        } else {
          amount = cartData?.total_pay;
        }
        // If user selected wallet but has some balance amount in order total, then it will deduct from card
        if (isWallet && usedWalletBalance > 0 && orderTotal <= 0) {
          placeOrder();
        } else {
          let obj = {
            user_id: userData?.id,
            amount: orderTotal,
            card_id: paymentCardData?.id,
            currency: "KYD",
            user_address_id: userSelectedAddress?.id,
          };
        //   setupPayerAuth(obj);
        }
      }
    } catch (ex) {
      setPlacingOrder(false);
      ErrorToaster("Something went wrong");
    }
  };


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC1Gh661ToYHtDs98IVxaZHi5_XqVMwy6Y",
    libraries: ["places"],
  });

  useEffect(() => {
    if (selectedType == "Delivery") {
      //takeaway
      if (!userSelectedAddress) {
        getShopAddresses(0, 0);
      } else {
        getShopAddresses(
          userSelectedAddress?.latitude,
          userSelectedAddress?.longitude
        );
      }
    }
    if (selectedType != "Delivery") {
      // it means the type is takeaway
      updateFee(0, 0, 0, 0);
    }

    setValue("instructions", userSelectedAddress?.instructions);
  }, [selectedType, userSelectedAddress, cartData]);

  function getDistanceBetweenTwoPoints(cord1, cord2) {
    console.warn("coordinate 1", cord1);
    console.warn("coordinate 2", cord2);

    if (
      cord1.latitude == cord2.latitude &&
      cord1.longitude == cord2.longitude
    ) {
      return 0;
    }

    const radlat1 = (Math.PI * cord1.latitude) / 180;
    const radlat2 = (Math.PI * cord2.latitude) / 180;

    const theta = cord1.longitude - cord2.longitude;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
      dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    // dist = dist * 1.609344; //convert miles to km

    return parseFloat(dist).toFixed(1);
  }

  useEffect(() => {
    setDistanceBtwRestaurant(
      getDistanceBetweenTwoPoints(
        {
          latitude: currentLocation?.latitude,
          longitude: currentLocation?.longitude,
        },
        {
          latitude: cartData?.addrsses?.[0]?.latitude,
          longitude: cartData?.addrsses?.[0]?.longitude,
        }
      )
    );
  }, [currentLocation, cartData]);
  console.log(currentLocation);

  useEffect(() => {
    getCartData();
    checkDeliveryFee();
    getWalletBalance();
    getPaymentCardHandler();
    getOneTimeLocation();
  }, []);

  if (!isLoaded) {
    return <div></div>;
  }
  return (
    <>

      <navbar className="checkout-header">
        <IconButton sx={{ background: Colors.grey, borderRadius: "50%" }} onClick={() => navigate(-1)} className="back-link">
          <ArrowBackIcon />
        </IconButton>
        <Box className="logo">
          <img src={Images.logo} alt="logo" className="checkoutLogo" />
        </Box>
        <Box>

        </Box>
      </navbar>
      <Box className="checkout-container">
        <Grid
          container
          item
          spacing={2}
          className="checkout-content"
          sx={{ mt: 1.4 }}
          direction={isSmallScreen ? "column-reverse" : "row"}
        >
          <Grid item xs={12} sm= {12} md={7.5} className="checkout-details">
            <Box>
              <Box
                className="delivery-heading"
                sx={{ marginBottom: "0px !important"  }}
              >
                <h2>Delivery details</h2>{" "}
                <ToggleButton
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                  isTakeaway={isTakeaway}
                  pickupDisabled={pickupDisabled}
                  deliveryDisabled={deliveryDisabled}
                  serveOption={serveOption}
                  optionEnabled={cartData?.pd_status}
                />
              </Box>
              <Box sx={{ marginBottom: "0px !important", textAlign: "center" }}>
                {deliveryDisabled && (
                  <Typography color="error" fontSize="0.75rem">
                    This restaurant is only offering Pickup orders at this time.
                  </Typography>
                )}
                {pickupDisabled && (
                  <Typography color="error" fontSize="0.75rem">
                    This restaurant is only offering Delivery orders at this time.
                  </Typography>
                )}
              </Box>
            </Box>

            {selectedType === "Delivery" && (
              <Box className="delivery-details card">
                <Box className="delivery-locationcontainer">
                  <Box className="delivery-location">
                    <p className="delivery-city" style={{ display: "flex" }}>
                      <LocationOnIcon />
                    </p>
                    <Box>
                      <p
                        className="delivery-country"
                        style={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        {userSelectedAddress?.building}
                      </p>
                      <p className="delivery-country">
                        {userSelectedAddress.map_address}
                      </p>
                    </Box>
                  </Box>
                  <button
                    className="edit-button"
                    onClick={() => {
                      setDialog(true);
                    }}
                  >
                    Edit
                  </button>
                </Box>
              </Box>
            )}
            {selectedType === "Pickup" && (
              <>
                <div
                  style={{
                    borderRadius: "16px",
                    overflow: "hidden",
                    margin: "16px 0",
                  }}
                >
                  <GoogleMap
                    mapContainerStyle={{ height: "200px", width: "100%" }}
                    center={{
                      lat: cartData?.addrsses?.[0]?.latitude,
                      lng: cartData?.addrsses?.[0]?.longitude,
                    }}
                    zoom={15}
                  >
                    <Marker
                      position={{
                        lat: cartData?.addrsses?.[0]?.latitude,
                        lng: cartData?.addrsses?.[0]?.longitude,
                      }}
                    />
                  </GoogleMap>
                </div>

                <Box
                  className="delivery-details card"
                  sx={{ paddingBottom: "0px !important" }}
                >
                  <Box className="delivery-locationcontainer">
                    <Box
                      className="delivery-location"
                      sx={{ alignItem: "center", gap: "20px  " }}
                    >
                      <p className="delivery-city" style={{ display: "flex" }}>
                        <img
                          style={{ width: "30px", height: "30px" }}
                          src={Images.store}
                        />
                      </p>
                      <Box>
                        <p
                          className="delivery-country"
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          {cartData?.addrsses?.[0]?.address}
                        </p>
                      </Box>
                    </Box>
                    {/* <button
                    className="edit-button"
                    onClick={() => {
                      setDialog(true);
                    }}
                  >
                    Edit
                  </button> */}
                  </Box>
                </Box>

                <Box
                  className="delivery-details card"
                  sx={{ paddingBottom: "0px !important" }}
                >
                  <Box className="delivery-locationcontainer">
                    <Box
                      className="delivery-location"
                      sx={{ alignItem: "center", gap: "20px  " }}
                    >
                      <p className="delivery-city" style={{ display: "flex" }}>
                        <img
                          style={{ width: "30px", height: "30px" }}
                          src={Images.relaxingWalk}
                        />
                      </p>
                      <Box>
                        <p
                          className="delivery-country"
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          {distanceBtwRestaurant + " miles"}
                        </p>
                      </Box>
                    </Box>
                    {/* <button
                    className="edit-button"
                    onClick={() => {
                      setDialog(true);
                    }}
                  >
                    Edit
                  </button> */}
                  </Box>
                </Box>
              </>
            )}
            {selectedType == "Delivery" &&
              cartData?.distancefee?.[0]?.pfee > 0 ? (
              <>
                <h2>Delivery options</h2>

                <Box
                  className={`option ${selectedDeliveryType === "Priority" ? "selected" : ""
                    }`}
                  onClick={() => handleSelectOption("Priority")}
                >
                  <Box
                    className="option-title"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "0px !important",
                    }}
                  >
                    <Box sx={{ display: "flex", marginBottom: "0px !important" }}>
                      <PersonIcon />
                      <Box>Priority</Box>
                    </Box>
                    <Box className="option-price">
                      {"+$" + cartData?.distancefee?.[0]?.pfee + ""}
                    </Box>
                  </Box>
                  <Box className="option-time" sx={{ color: "#9b9b9b" }}>
                    Delivered directly to you
                  </Box>
                </Box>
              </>
            ) : null}
            {selectedType == "Delivery" ? (
              <Box
                className={`option ${selectedDeliveryType === "Standard" ? "selected" : ""
                  }`}
                onClick={() => handleSelectOption("Standard")}
              >
                <Box
                  className="option-title"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0px !important",
                  }}
                >
                  <Box sx={{ display: "flex", marginBottom: "0px !important" }}>
                    <AccessTimeIcon />
                    <Box>Standard</Box>
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box className="payment-details card">
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  Payment
                </Typography>

                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    margin: "1rem 0",
                    padding: "10px",
                    minHeight: "100px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setIsWalletEnabled(true);
                  }}
                >
                  <Box style={{ width: "36px", height: "36px" }}>
                    <WalletIcon style={{ width: "100%", height: "100%" }} />
                  </Box>

                  {walletBalance >= 0 && (
                    <Box
                      style={{
                        flex: 1,
                        padding: "0 16px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Pay from wallet
                      </Typography>

                      <Typography
                        variant="body2"
                        style={{ marginTop: "10px", color: "black" }}
                      >
                        Balance: ${walletBalance.toFixed(2)}
                        {console.log(walletBalance)}
                      </Typography>

                      <Box
                        style={{
                          height: "1px",
                          backgroundColor: "#a9a9a9",
                          width: "100%",
                          margin: "15px 0",
                        }}
                      ></Box>

                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2" style={{ color: "black" }}>
                          Use my balance for this order
                        </Typography>
                        <Switch
                          checked={isWalletEnabled}
                          onChange={handleSwitchChange}
                          color="primary"
                          size="small"
                        />
                      </Box>
                    </Box>
                  )}
                </Card>

                {!(isWallet && usedWalletBalance > 0 && orderTotal <= 0) && (
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "1rem 0",
                      borderBottom: "1px solid #d3d3d3",
                      paddingBottom: "16px",
                      cursor: "pointer",
                      p: 1.5,
                    }}
                    onClick={() => {
                      setPaymentOptionModal(true);

                      //  fromCheckout: true
                    }}
                  >
                    <Box style={{ width: "36px", height: "36px" }}>
                      <CreditCardIcon style={{ width: "100%", height: "100%" }} />
                    </Box>

                    <Box style={{ flex: 1, padding: "0 16px" }}>
                      <Typography variant="body1" fontWeight="bold">
                        {paymentCardData?.last_four
                          ? paymentCardData.last_four
                          : "Select Payment Method"}
                      </Typography>

                      {isWallet && orderTotal > 0 && (
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: "14px",
                            color: paymentCardData?.last_four ? "#a9a9a9" : "red",
                          }}
                        >
                          {paymentCardData?.last_four
                            ? "Note: Your wallet does not have a sufficient balance for this order. The remaining balance will be charged to your card."
                            : "Your wallet does not have sufficient balance for this order. Choose a different payment method."}
                        </Typography>
                      )}
                    </Box>

                    <IconButton style={{ padding: "6px" }}>
                      <ArrowForwardIosIcon
                        style={{ width: "24px", height: "24px" }}
                      />
                    </IconButton>
                  </Card>
                )}
              </Box>

            </Box>
            {console.log(userSelectedAddress)}
            <button
              className="primary-button continue-button"
              disabled={
                cartData?.status !== "active" ||
                cartData?.shop_status === "Closed"
              }
              onClick={() => {
                if (!placingOrder) {
                  console.log("Placing Order");
                  if (paymentCardData?.last_four || isWallet) {
                    console.log("is wallet last four");
                    if (isWallet) {
                      console.log("is wallet");
                      if (
                        isWallet &&
                        usedWalletBalance < orderTotal &&
                        !paymentCardData?.last_four
                      ) {
                        ErrorToaster(
                          "Choose a different payment method or top up your wallet."
                        );
                      }

                      else {
                       
                        submitOrder();
                      }
                    } else {
                     
                      submitOrder();
                    }
                  } else {
                    ErrorToaster("Choose payment method to proceed");
                    console.log("Choose payment method to proceed");
                  }
                }
              }}
            >
              {cartData?.status !== "active"
                ? "Shop is currently unavailable"
                : cartData?.shop_status !== "Closed"
                  ? `Place Order $ ${isWallet && usedWalletBalance > 0 && orderTotal <= 0
                    ? usedWalletBalance
                    : orderTotal
                  }`
                  : "Shop is closed at the moment"}
            </button>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className="order-summary">
            <Box className="order-restaurant">
              <img
                src={baseUrl + "/docs/files/shops/" + cartData?.shopavtar}
                alt="Tortilla [Fresh Burritos]"
                className="restaurant-img"
              />
              <Box className="restaurant-info">
                <h3>{cartData?.shopname}</h3>
                <p>{cartData?.addrsses?.map((item) => item?.address)}</p>
              </Box>
            </Box>
            <Box className="cart-summary">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                onClick={toggleDropdown}
                className="cart-header"
              >
                <Box display="flex" alignItems="center">
                  <ShoppingCartIcon />
                  <Typography
                    variant="body1"
                    style={{ marginLeft: 8, fontSize: "14px" }}
                  >
                    Cart summary ({cartData?.items?.length}{" "}
                    {cartData?.items?.length === 1 ? "item" : "items"})
                  </Typography>
                </Box>
                <IconButton size="small">
                  {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Collapse in={isOpen}>
                <Box className="cart-items">
                  {Array.isArray(cartData?.items) && cartData.items.length > 0 ? (
                    cartData.items.map((item, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        <ListItem>
                          <Box sx={{ width: "100%" }}>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={6}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      textTransform: "uppercase",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Box>
                              </Grid>

                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      borderRadius: "50%",
                                      objectFit: "fill",
                                    }}
                                    src={
                                      baseUrl +
                                      "/docs/files/products/" +
                                      item?.pic
                                    }
                                    alt={item.name}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    mt: 1,
                                    background: "#d8d8d8",
                                    borderRadius: "20px",
                                    padding: "0px 8px",
                                    width: "90px",
                                  }}
                                >
                                  <IconButton
                                    onClick={() =>
                                      handleDecrement(state.itemIndex, index)
                                    }
                                  >
                                    {quantities[`${state.itemIndex}-${index}`] >
                                      1 ? (
                                      <RemoveIcon />
                                    ) : (
                                      <DeleteIcon />
                                    )}
                                  </IconButton>
                                  <Typography variant="body2">
                                    {quantities[`${state.itemIndex}-${index}`]}
                                  </Typography>
                                  <IconButton
                                    onClick={() =>
                                      handleIncrement(state.itemIndex, index)
                                    }
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "18px", marginTop: "20px" }}
                                >
                                  ${item.price}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </ListItem>
                        {item.itemIngs.length > 0 && (
                          <Box sx={{ mt: 1, pl: 2 }}>
                            <Grid container spacing={1} sx={{ mt: 1 }}>
                              {item.itemIngs.map((ing, ingIndex) => (
                                <Grid container item key={ingIndex} spacing={1}>
                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      {ing.modifier}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      {ing.name}-( ${ing.price})
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        )}
                        <Divider sx={{ my: 1 }} />
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2" className="no-items">
                      No items available.
                    </Typography>
                  )}
                </Box>
              </Collapse>
              <Divider sx={{ my: 1 }} />
              <Box sx={{ mt: 1 }}>
                <Box sx={{ fontWeight: "bold", mb: 1 }}>
                  Instructions for Delivery Person
                </Box>
                <TextField
                  multiline
                  minRows={3}
                  {...register("instructions")}
                  placeholder="Example: I'll be on the corner"
                  sx={{
                    width: "100%",
                    paddingTop: "8px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    resize: "none",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#c4c4c4", // No border by default
                      },
                      "&:hover fieldset": {
                        borderColor: "#c4c4c4", // No border on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black", // No border when focused
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#c4c4c4", // No border for notched outline
                    },
                    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                      borderBottom: "none", // Remove underline before and after
                    },
                  }}
                />
              </Box>
            </Box>
            <Box className="promotion" onClick={() => setPromotionDialog(true)}>
              <Typography variant="h6" className="promotion-title">
                Promotion
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="promotion-body"
                marginTop="10px"
              >
                {appliedPromoCode?.length > 0 ? (
                  <>
                    <Box display="flex" alignItems="center">
                      <LocalOfferIcon />
                      <Box>
                        <Typography
                          variant="body1"
                          style={{ marginLeft: 8, fontWeight: "bold" }}
                        >
                          Promotion Applied
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            marginLeft: 8,
                            marginTop: "10px",
                            color: "rgb(195 195 195)",
                          }}
                        >
                          {renderPromo()}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            marginLeft: 8,
                            marginTop: "10px",
                            color: promocodeValidity ? Colors.green : Colors.red,
                          }}
                        >
                          {validityText}
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton size="small">
                      <ChevronRightIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Box display="flex" alignItems="center">
                      <LocalOfferIcon />
                      <Typography variant="body1" style={{ marginLeft: 8 }}>
                        Add promo code
                      </Typography>
                    </Box>
                    <IconButton size="small">
                      <ChevronRightIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Add a tip</Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  ${customTip ? parseFloat(customTip).toFixed(2) : "0.00"}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", marginY: 2 }}
              >
                Add some sunshine to your delivery person's day!
              </Typography>
              <Grid container spacing={1} sx={{ marginTop: 2, marginBottom: 1 }}>
                {tipOptions.map((item, index) => (
                  <Grid item key={index}>
                    <Box
                      variant="contained"
                      color={item.selected ? "primary" : "default"}
                      onClick={() => handlePress(index)}
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                        p: 2,
                        borderRadius: "20px",
                        padding: "6px 10px",
                        minWidth: 65,
                        margin: 0.5,
                        background: item.selected ? Colors.primary : Colors.grey,
                        color: item.selected ? Colors.white : "",
                      }}
                    >
                      {item.label}
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {othersSelected && customTip >= 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginY: 2,
                    padding: 1,
                  }}
                >
                  <TextField
                    placeholder="Enter custom tip amount"
                    type="text"
                    onChange={handleCustomTipChange}
                    sx={{
                      flex: 1,
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          borderColor: "black",
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: "0px",
                      padding: "15px  15px",
                      background: Colors.black,
                      ":hover": {
                        background: Colors.black,
                      },
                    }}
                    onClick={handleAddTip}
                  >
                    Add
                  </Button>
                </Box>
              ) : null}
              <Divider sx={{ marginY: 2 }} />
            </Box>
            <Box className="order-total">
              <Typography
                variant="h6"
                className="order-total-title"
                sx={{ fontWeight: "bold" }}
              >
                Order total
              </Typography>
              {itemTotal > 0 ? (
                <Box className="total-row">
                  <Typography
                    variant="body1"
                    className="total-label total-label-bold"
                  >
                    Item Total
                  </Typography>
                  <Typography
                    variant="body1"
                    className="total-value total-value-bold"
                  >
                    ${itemTotal.toFixed(2)}
                  </Typography>
                </Box>
              ) : null}
              {customTip > 0 ? (
                <Box className="total-row">
                  <Typography
                    variant="body1"
                    className="total-label total-label-bold"
                  >
                    Tip
                  </Typography>
                  <Typography
                    variant="body1"
                    className="total-value total-value-bold"
                  >
                    ${parseFloat(customTip)?.toFixed(2)}
                  </Typography>
                </Box>
              ) : null}
              {smallOrderFee > 0 ? (
                <Box className="total-row">
                  <Typography
                    variant="body1"
                    className="total-label total-label-bold"
                  >
                    Small Order Fee
                  </Typography>
                  <Typography
                    variant="body1"
                    className="total-value total-value-bold"
                  >
                    ${smallOrderFee.toFixed(2)}
                  </Typography>
                </Box>
              ) : null}
              {deliveryFee > 0 && selectedType == "Delivery" ? (
                <Box className="total-row">
                  <Typography
                    variant="body1"
                    className="total-label total-label-bold"
                  >
                    Delivery Fee
                  </Typography>
                  <Typography
                    variant="body1"
                    className="total-value total-value-bold"
                  >
                    $
                    {selectedDeliveryType == "Priority"
                      ? deliveryPFee.toFixed(2)
                      : deliveryFee.toFixed(2)}
                  </Typography>
                </Box>
              ) : null}
              {distanceFee > 0 && selectedType == "Delivery" ? (
                <Box className="total-row">
                  <Typography
                    variant="body1"
                    className="total-label total-label-bold"
                  >
                    Distance Fee
                  </Typography>
                  <Typography
                    variant="body1"
                    className="total-value total-value-bold"
                  >
                    $
                    {selectedDeliveryType == "Priority"
                      ? distancePFee.toFixed(2)
                      : distanceFee.toFixed(2)}
                  </Typography>
                </Box>
              ) : null}
              {promoCodeDiscount > 0 ? (
                <Box className="total-row">
                  <Typography
                    variant="body1"
                    className="total-label total-label-bold"
                  >
                    Distance Fee
                  </Typography>
                  <Typography
                    variant="body1"
                    className="total-value total-value-bold"
                  >
                    ${"-" + promoCodeDiscount}
                  </Typography>
                </Box>
              ) : null}

              {usedWalletBalance > 0 ? (
                <Box className="total-row">
                  <Typography
                    variant="body1"
                    className="total-label total-label-bold"
                  >
                    Distance Fee
                  </Typography>
                  <Typography
                    variant="body1"
                    className="total-value total-value-bold"
                  >
                    ${`- ` + usedWalletBalance}
                  </Typography>
                </Box>
              ) : null}

              <Box className="total-row total">
                <Typography
                  variant="body1"
                  className="total-label total-label-bold"
                >
                  Subtotal
                </Typography>
                <Typography
                  variant="body1"
                  className="total-value total-value-bold"
                >
                  ${subTotal}
                </Typography>
              </Box>
              <Box className="total-row total">
                <Typography
                  variant="body1"
                  className="total-label total-label-bold"
                >
                  Total
                </Typography>
                <Typography
                  variant="body1"
                  className="total-value total-value-bold"
                >
                  ${orderTotal}
                </Typography>
              </Box>
            </Box>
            {/* <Box className="order-allergies">
            <p>
              ALLERGIES: If you or someone you’re ordering for has an allergy,
              please contact the merchant directly to let them know. If you’re
              not around when the delivery person arrives, they’ll leave your
              order at the door. By placing your order, you agree to take full
              responsibility for it once it’s delivered. Orders containing
              alcohol or other restricted items may not be eligible for leave at
              door and will be returned to the store if you are not available.
              Whilst we, and our restaurant partners, have safety measures to
              mitigate food safety risk, couriers may be delivering more than
              one order so we cannot eliminate the risk of cross-contamination
              from allergens.
            </p>
          </Box> */}
          </Grid>
        </Grid>

        <TwoButtonModal
          pressCancelBtn={() => {
            setShowDeleteItemPopup(false);
            setPlacingOrder(false);
          }}
          modalVisible={showDeleteItemPopup}
          dispatch={() => {
            setShowDeleteItemPopup(false);
            handleDeleteItem(deleteItemIndex);
          }}
          text={"Are you sure you want to remove this item from your cart?"}
          successBtnText={"Yes"}
          rejectBtnText={"No"}
        />

        <TwoButtonModal
          pressCancelBtn={() => {
            setShowAddressCnfrmModal(false);
            setPlacingOrder(false);
          }}
          modalVisible={showAddressCnfrmModal}
          dispatch={() => {
            setShowAddressCnfrmModal(false);
            setAddressConfirmed(true);
            setPlacingOrder(true);
            let amount;
            if (smallOrderFee > 0) {
              amount = cartData?.total_pay + cartData?.smallorderfees[0]?.fee;
            } else {
              amount = cartData?.total_pay;
            }
            if (isWallet && usedWalletBalance > 0 && orderTotal <= 0) {
              console.log("place order called");
              placeOrder();
            } else {
              let obj = {
                user_id: userData?.id,
                amount: orderTotal,
                card_id: paymentCardData?.id,
                currency: "KYD",
                customerActualLatlng: userSelectedAddress?.id,
              };
              console.log(obj);
              payment3dHandler(obj)
            //   setupPayerAuth(obj);
            }
          }}
          text={
            "Are you sure you want your food delivered to " +
            userSelectedAddress?.map_address +
            ", Building: " +
            userSelectedAddress?.building +
            ", Street: " +
            userSelectedAddress?.street +
            ", Landmark: " +
            userSelectedAddress?.landmark +
            ", City: " +
            userSelectedAddress?.city
          }
          successBtnText={"Yes"}
          rejectBtnText={"No"}
        />

        {dialog && (
          <>
            <SimpleDialog
              open={dialog}
              onClose={() => setDialog(false)}
              title="Select Address"
            >
              <Box>
                <Location stateData={state} setDialog={setDialog} />
              </Box>
            </SimpleDialog>
          </>
        )}
        {promotiondialog && (
          <>
            <SimpleDialog
              open={promotiondialog}
              onClose={() => setPromotionDialog(false)}
              title="Add Promotions"
            >
              <Box>
                <Promotions setPromotionDialog={setPromotionDialog} />
              </Box>
            </SimpleDialog>
          </>
        )}

        {paymentOptionModal && (
          <SimpleDialog
            open={paymentOptionModal}
            onClose={() => setPaymentOptionModal(false)}
            title="Add Payment Methods"
          >
            <AddPaymentMethodModal
              modalVisible={addPaymentMethodModal}
              setIsWalletEnabled={setIsWalletEnabled}
              setClose={() => setAddPaymentMethodModal(false)}
              fromWallet={false}
              onClick={() => {
                setAddCardModal(true);
                setAddPaymentMethodModal(false);
              }}
              // loader={loader}
              removeLoader={removeLoader}
              removeCard={removeCardHandler}
              cards={cards}
              setAddPaymentMethodModal={() =>
                setAddPaymentMethodModal(!addPaymentMethodModal)
              }
              removeModal={removeCardModal}
              setRemoveModal={setRemoveCardModal}
              closeAllModal={() => {
                setPaymentOptionModal(false);
                setPaymentMethodOptionModal(false);
                setAddPaymentMethodModal(false);
              }}
            />
          </SimpleDialog>
        )}

        {deviceAuthModal && (
          <CybersourceDeviceAuthenticationModal
            modalVisible={deviceAuthModal}
            setClose={() => setDeviceAuthModal(false)}
            authToken={paymentAuthToken}
            url={paymentAuthUrl}
            loadDeviceCheck={loadDeviceCheck}
            onSuccess={(type) => {
              console.warn("device authentication modal type:", type);
              console.warn("device authentication modal data:", type);

              if (type == "DEVICE_AUTHENTICATION") {
                setDeviceAuthModal(false);
                let obj = {
                  user_id: userData?.id,
                  amount: orderTotal,
                  card_id: paymentCardData?.id,
                  // address: userAddress?.map_address,
                  user_address_id: userSelectedAddress?.id,
                  //  reference_id: referenceId,
                  currency: "KYD",
                };
                // payerAuthenticationCheckEnrollmentService(obj);
              }
            }}
            onFailure={() => {
              setPlacingOrder(false);
            }}
            onBackPress={() => {
              setDeviceAuthModal(false);
              setPlacingOrder(false);
            }}
          />
        )}
        {paymentAuthModal && (
          <CybersourceAuthenticationWebViewModal
            modalVisible={paymentAuthModal}
            setClose={() => setPaymentAuthModal(false)}
            onPress={() => setPaymentAuthModal(true)}
            authToken={paymentAuthToken}
            url={paymentAuthUrl}
            loadDeviceCheck={loadDeviceCheck}
            onSuccess={(type, data) => {
              setPaymentAuthModal(false);
              console.warn("authentication modal type:", type);
              console.warn("authentication modal data", data);
              if (type == "DEVICE_AUTHENTICATION") {
                // console.warn(data?.Key);
                var params = {
                  user_id: userData?.id,
                  transaction_id: data?.Key,
                };
                // payerAuthenticationValidationService(params);
              }
            }}
            onFailure={() => { }}
            onBackPress={() => {
              setPaymentAuthModal(false);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default CheckoutPage;
