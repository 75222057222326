import React from "react";
import { Grid } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./UpperArrow1.css"

export default function UpperArrowSlider({ slidesToShow, slidesToScroll, children, prevArrowClassName, nextArrowClassName, className, data }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    prevArrow: <div className={prevArrowClassName} />,
    nextArrow: <div className={nextArrowClassName} />,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 763,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 463,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <Grid container justifyContent="space-between" ></Grid>
      <Slider className={className} {...settings}>{children && React.Children.count(children) > 0 ? children : <div></div>}
      </Slider>
    </>
  );
}
